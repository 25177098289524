import { GETFANCYLIST, UPDATEFANCYLIST } from './url'
import axios from 'axios';

class FancyServiceClass {

    async getFancyListService(marketid) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`

        };
        return await axios.get(`${GETFANCYLIST}?MarketId=${marketid}`, {
            headers: headers,
        });
    }
    async postUpdateFancytService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.post(`${UPDATEFANCYLIST}`, data, {
            headers: headers,
        });
    }

}

const FancyService = new FancyServiceClass()

export default FancyService;