import React, { useEffect, useState } from 'react';
import actions from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import store from '../../redux/store';
import { setSelectionData } from '../../redux/slices/bookEventSlice';
import { isSuperAdmin,isRoleExits } from '../../utils';

const FancyUnDeclare = ({  updatedFancyOddsFareRate, marketID,eventId}) => {


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the threshold as needed
    
    const [expandedItem, setExpandedItem] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        
    }, [ updatedFancyOddsFareRate]);


    const bookHandler = ((runner) => {
        store.dispatch(setSelectionData(runner))
        setExpandedItem(null);
        actions.getFancyCurrent({ marketId: marketID, selectionId: runner.SelectionId })

    });

    const togglePopup = (selectionId) => {
        if (expandedItem === selectionId) {
            setExpandedItem(null); // Close the popup if it's already open
        } else {
            setExpandedItem(selectionId); // Open the popup
        }
    };

    const fancyEditHandler = ((data) => {
        navigate('/fancy-edit', { state: { ...data, marketID, eventId } });
    });
    const fancyBetListHandler = ((runner, type) => {
        setExpandedItem(null);
        actions.getMarketBetList({ marketId: marketID, fancyId: runner.SelectionId, MarketType: type })
    });
    return (
        <>
            <div className="tag-container white-tag-container py-1 align-items-center">
                <span className="tag-text text-white"> Fancy UnDeclare List</span>
               
            </div>
            <div className="table-responsive">
                <table className="table table-striped">
                    
                    <tbody>
                        {updatedFancyOddsFareRate?.map(runner => {
                           
                            return (
                                <tr key={runner.SelectionId} className="td-tr-botton-border" style={{ "position": "relative" }}>
                                    <td className="font-weight-bold" style={{ verticalAlign: 'middle' }}>
                                        <div className="d-flex align-items-center">
                                            <div className="position-relative ms-2 d-inline-block">
                                             
                                            </div>
                                            <div>
                                                <span className={!isMobile ? '' : runner.RunnerName.length < 25 ? '' : 'marquee-text'}>
                                                    {runner.RunnerName}
                                                    <i className="fas fa-exclamation-circle fa-1x text-gray-800 ml-1" title={`Min/MAX ${runner.min}-${runner.max}`} />
                                                </span>
                                               
                                            </div>
                                        </div>

                                    </td>
                                    <td style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
                                    {isRoleExits("fancy_position")  && <a className="btn btn-success btn-sm text-green mx-2" role="button" data-toggle="modal" data-target="#currentFancyModal" onClick={() => bookHandler(runner)}>Book</a>}
                                    {isRoleExits("fancymanual")  && <a role="button" className="btn btn-success btn-sm text-green mx-2" onClick={() => fancyEditHandler(runner)}>FancyEdit</a>}
                                    {isRoleExits("fancy_position")  && <a role="button" data-toggle="modal" data-target="#fancyBetListModal" onClick={() => fancyBetListHandler(runner, 'FANCY')}>FancyBetList</a>}
                                                                         
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default FancyUnDeclare;