import { config } from "../constants";

export const getApiUrl = (endpoint) => {
  return config.API_BASE_URL + endpoint;
};
export const getApiUrl2 = (endpoint) => {
  return "https://create.shri999.com" + endpoint;
};

//auth
export const LOGINAPI = getApiUrl("/api/Authenticate/auth");
export const LOGINAPISUPER = getApiUrl("/api/Authenticate/AuthSuper");

export const GETUSERLIST = getApiUrl("/api/SignUpModels/getUserList"); //User LIST
export const CHANGEPWD = getApiUrl("/api/SignUpModels/changePwd"); //Change psswd
export const GETUSERSEARCHLIST = getApiUrl(
  "/api/SignUpModels/GetClientSearchList"
); //User LIST
export const POSTSIGNUPMODEL = getApiUrl("/api/SignUpModels/PostSignUpModel"); //Add User
export const UPDATESIGNUPMODEL = getApiUrl(
  "/api/SignUpModels/UpadteSignUpModel"
); //update User
export const GETBALANCEDETAILS = getApiUrl(
  "/api/MarketResultModels/GetAdminBalanceDetail"
);

//match create
export const GETMARKETLISTAPI = getApiUrl2("/markets");
export const GETCOMPETITONLISTAPI = getApiUrl2("/competition");
export const GETMARKETBYCOMPLISTAPI = getApiUrl2("/competitionwise");

export const GETMARKETIDSLISTAPI = getApiUrl(
  "/api/MarketModels/GetExistMarketList"
);
//export const POSTMARKETMODELAPI = getApiUrl('/api/MarketModels/PostMarketModel');
export const POSTMARKETMODELAPI = getApiUrl("/api/MarketModels/PostMarket");
export const POSTBULKMARKETMODELAPI = getApiUrl(
  "/api/MarketModels/PostBulkMarketData"
);
export const GETOPENMATCHAPI = getApiUrl("/api/MarketModels/getEventListAdmin");
//Dashboard
export const GETMARKETANALYSISAPI = getApiUrl(
  "/api/MarketModels/GetMarkerAnalysis"
);

export const GETSUSPENDEDMATCHAPI = getApiUrl(
  "/api/MarketModels/getSuspendedMarketListWithRunners"
);
export const GETCOMPLETEDMATCHAPI = getApiUrl(
  "/api/MarketModels/getCompletedMarketListWithRunners"
);
export const GETEVENTLISTAPI = getApiUrl(
  "/api/MarketModels/GetEventListWithRunner"
);
export const GETOPENRETESEAPI = getApiUrl("/api/MarketModels/openRates");
export const GETEVENTCONTROLAPI = getApiUrl("/api/MarketModels/eventControl");
export const GETMATCHCONTROLAPI = getApiUrl("/api/MarketModels/MarketControl");
export const GETOPENCLOSEMARKETAPI = getApiUrl(
  "/api/MarketModels/openCloseMatch"
);
export const GETMARKETSETTINGAPI = getApiUrl(
  "/api/MarketModels/GetMarketSettingModel"
);
export const POSTMARKETSETTINGAPI = getApiUrl(
  "/api/MarketModels/PostMarketSettingModel"
);
export const GETMARKETNAMEAPI = getApiUrl("/api/MarketModels/GetMarketName");
export const GETMARKETCURRENTBETSAPI = getApiUrl(
  "/api/UserBetModels/getMarketCurrentBetsFilter"
);
export const GETRUNNINGFANCYAPI = getApiUrl(
  "/api/MarketResultModels/getRunnigFancyList"
);
export const GETRESULTLISTAPI = getApiUrl(
  "/api/MarketResultModels/GetResultList"
);
export const GETROLLBACKFACNYAPI = getApiUrl(
  "/api/MarketResultModels/rollBackFancyResult"
);
export const GETTERMINATEFANCYAPI = getApiUrl(
  "/api/AccountStatementModels/terminateFancy"
);
export const GETTRANSFERFANCYCOINSAPI = getApiUrl(
  "/api/MarketResultModels/transferFancyCoins"
);
export const SAVECUSTOMFANCYAPI = getApiUrl(
  "/api/CustomeMarket/SavetCustomeFancy"
);
export const POSTMARKETCUSTOMAPI = getApiUrl(
  "/api/MarketModels/PostMarketCustome"
);
export const POSTDECLAREWINNER = getApiUrl(
  "/api/MarketResultModels/transferMatchCoins"
);
export const POSTROLLBACKMATCHAPI = getApiUrl(
  "/api/MarketResultModels/rollBackMatchResult"
);

//Rate Apies
export const GETBETFAREBMFANCYAPI =
  "https://api.shriexchange.com/api/betfair/bmFancy"; //bookmaker and fancymarket
export const GETBETFAREMARKETOTHERAPI =
  "https://betfair.shriexchange.com/api/Odds/betfair";
export const GETBETFARETOSSAPI = getApiUrl("/api/MarketModels/GetTossOdds"); //Only Toss Odds

export const GETEVENTDETAIL = getApiUrl("/api/MarketModels/GetEventDetail");
export const GETUSERBETMODELAPI = getApiUrl(
  "/api/UserBetModels/GetUserBetModelByAdminEId"
); //Pass event ID
export const GETPROFITLOSSRUNNERAPI = getApiUrl(
  "/api/ProfitLossModels/getProfitLossOnRunners"
); //Pass market ID
export const GETPROFITLOSSRUNNEREVNTAPI = getApiUrl(
  "/api/ProfitLossModels/getProfitLossOnRunnersEvnt"
); //Pass market ID
export const GETFANCYPOSTIONAPI = getApiUrl(
  "/api/ProfitLossModels/getFancyPositionByMarket"
); //Pass market ID
export const BETBLOCKMARKET = getApiUrl(
  "/api/BlockMarketModels/PutBlockMarketModel"
); //Block Market
export const GETUSERBOOK = getApiUrl("/api/MarketResultModels/GetUserBook"); //User Book
export const GETBLOCKEVENTLIST = getApiUrl(
  "/api/BlockEventModels/GetBlockEventList"
);
export const GETBLOCKSPORTLIST = getApiUrl(
  "/api/BlockEventModels/GetBlockSportsList"
);
export const POSTBLOCKSPORT = getApiUrl(
  "/api/BlockEventModels/PutBlockSportsModel"
);
export const POSTBLOCKSEVENT = getApiUrl(
  "/api/BlockEventModels/PutBlockMarketModel"
);

//
export const POSTDELETEMARKETTYPE = getApiUrl(
  "/api/UserBetModels/deletebetsbymarkettype"
); //delete User Bets
export const GETDELETEUSERBET = getApiUrl("/api/UserBetModels/deleteUserBets"); //delete User Bets
export const POSTFANCYBETADMIN = getApiUrl(
  "/api/UserBetModels/PostFancyBetAdmin"
); //fancy bet admin
export const POSTMARKETBETADMIN = getApiUrl("/api/UserBetModels/PostBetAdmin");
export const GETMARKETBETLIST = getApiUrl(
  "/api/UserBetModels/GetMarketBetList"
); //fancy bet list
export const UPDATEFACNYSTATUS = getApiUrl(
  "/api/CustomeMarket/UpdateFancyStatus"
); //fancy bet admin //?MarketId=1&fancyid=1&status=true
export const POSTCUSTOMFANCY = getApiUrl("/api/CustomeMarket/PostCustomeFancy");
export const GETEXPOSERBETLIST = getApiUrl(
  "/api/UserBetModels/GetUserbetsLibility"
);

export const GETCUSTOMEMARKET = getApiUrl(
  "/api/CustomeMarket/GetCustomeMarket"
); //User Book
export const POSTCUSTOMEMARKET = getApiUrl(
  "/api/CustomeMarket/PostCustomeMarket"
); //User Book

export const GETSCOREAPI = "https://score.shriexchange.com/api/MatchOdds/Score"; //Get Score
export const GETTENNISSCORE =
  "https://score.shriexchange.com/api/MatchOdds/tennisscore"; //Get Score

//REPORT
export const PROFITLOSSBYUSER = getApiUrl("/api/Reports/GetProfitLossByUser");
export const PLBYUSERSPOTS = getApiUrl("/api/Reports/GetPLByUserSpots");
export const ACCOUNTSTATEMENT = getApiUrl(
  "/api/AccountStatementModels/GetAccountStatementModelNew"
);
export const MARKETSHARINGDETAIL = getApiUrl(
  "/api/UserBetModels/getMarketBetsOfUser"
);
export const OVERALLPANDL = getApiUrl(
  "/api/MarketResultModels/getOverAllPandL"
);
export const GETUSERLOG = getApiUrl("/api/Reports/GetUserLog");
export const GETCHIPSUMMARY = getApiUrl(
  "/api/MarketResultModels/getChipSummaryDetails"
);
export const SETTLEMENT = getApiUrl("/api/AmountModels/settlement");
export const GETSETTLEMENTSUMMARY = getApiUrl(
  "/api/AccountStatementModels/getSettlementSummary"
);
export const PROFITLOSSSPORTS = getApiUrl("/api/Reports/GetProfitLossSports");
export const PROFITLOSSEVENT = getApiUrl("/api/Reports/GetProfitLossEvent");
export const GETMARKETBETPLLIST = getApiUrl(
  "/api/Reports/GetAllBetsPLByMarket"
); //market bet list

export const GETSHARINGDETAILS = getApiUrl("/api/Reports/getSharingDetails");
export const GETDELETEBET = getApiUrl("/api/Reports/GetAllDeletedBets");
export const GETEVENTNAME = getApiUrl("/api/Reports/GetEventNameList");
export const GETSUPERBET = getApiUrl("/api/Reports/GetAllAdminBets");
export const GETCASINOBET = getApiUrl("/api/Reports/GetCasinoLiveBet");

//Fancy Settings
export const GETFANCYLIST = getApiUrl("/api/CustomeMarket/GetFancyList");
export const UPDATEFANCYLIST = getApiUrl("/api/CustomeMarket/UpdateFancyList");

export const GETACTIVEFANCYAPI = getApiUrl(
  "/api/CustomeMarket/GetCustomeFancy"
);
export const GETCUSTOMEBMAPI = getApiUrl("/api/CustomeMarket/GetCustomeMarket");
export const GETFANCYCURRENT = getApiUrl(
  "/api/UserBetModels/getFancyCurrentPosition"
);
export const GETUNDECTAREFANCYAPI = getApiUrl(
  "/api/CustomeMarket/getFancyUnDeclareList"
);
export const GETCUSTOMFANCYBYIDAPI = getApiUrl(
  "/api/CustomeMarket/GetCustomeFancyById"
);

//SignalR hub
export const SIGNALR_HUB_URL = getApiUrl(
  "/MessageHub?userId=" +
    encodeURIComponent(
      JSON.parse(localStorage.getItem("loginUserDetails"))?.uid
    )
);

//client
export const GETCLIENTLIST = getApiUrl("/api/SignUpModels/GetClientList");
export const GETCLIENTNEWLIST = getApiUrl(
  "/api/SignUpModels/GetClientSearchNewList"
);
export const GETCLIENTFINDLIST = getApiUrl(
  "/api/SignUpModels/GetClientFindListAsync"
);

export const UPDATECLIENTSTATUS = getApiUrl(
  "/api/SignUpModels/ClientUpdateStartus"
);
export const CREATECLIENTSTATUS = getApiUrl(
  "/api/SignUpModels/PostSignUpModel"
);
export const GETAMOUNTMODAL = getApiUrl("/api/SignUpModels/GetAmountModel");
export const SAVEAMOUNTMODAL = getApiUrl("/api/SignUpModels/SaveAmountModel");
export const USERSETTINGAPI = getApiUrl(
  "/api/UserSettingModels/GetUserSettingModelByUserId"
);
export const SAVEUSERSETTINGAPI = getApiUrl(
  "/api/UserSettingModels/PutUserSettingModel"
);
export const SAVEALLUSERSETTINGAPI = getApiUrl(
  "/api/UserSettingModels/PutAllUserSettingModel"
);

//Role
export const GETALLROLES = getApiUrl("/api/UserRoleModels/GetAllRoles");
export const GETROLEBYUSER = getApiUrl("/api/UserRoleModels/GetRolesByUser");
export const SAVEROLE = getApiUrl("/api/UserRoleModels/SaveRolesUser");

//News
export const SAVENEWS = getApiUrl("/api/NewsModels/SaveNews");
export const GETNEWSLIST = getApiUrl("/api/NewsModels/GetNewsAll");
export const GETNEWSAPI = getApiUrl("/api/NewsModels/GetNews");
//ClientStatement
export const ACCOUNTSTATEMENTAPI = getApiUrl(
  "/api/Reports/GetAccountStatementModel"
);
export const ACCOUNTBETHISTORYAPI = getApiUrl(
  "/api/UserBetModels/getMarketBetsOfUser"
);
export const ACCOUNTFANCYBETHISTORYAPI = getApiUrl(
  "/api/UserBetModels/getFancyBetsOfUser"
);
