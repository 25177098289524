import moment from 'moment';

export const formattedDateTime = (DateTime) => {
    return moment(DateTime).utc().local().format("D MMM YYYY hh:mm A");
}

export const isSuperAdmin = () => {
    let Role = JSON.parse(localStorage.getItem("loginUserDetails"))?.Role
    if (Role === "Manager" || Role === "Admin" || Role === "SubAdmin" || Role === "Master" || Role === "Agent") {
        return false;
    }

    return true;
}

export const isRoleExits = (role) => {
    let jsonData = JSON.parse(localStorage.getItem("ManagerRole"))
    let Role = JSON.parse(localStorage.getItem("loginUserDetails"))?.Role
    if (Role === "Manager" && jsonData) {
        return jsonData?.some(item => item === role)
    }
    if (Role === "Manager" || Role === "Admin" || Role === "SubAdmin" || Role === "Master" || Role === "Agent") {
        return false;
    }

    return true;
}

export const showForAllExceptManager = () => {
    let Role = JSON.parse(localStorage.getItem("loginUserDetails"))?.Role;
    if (Role === "Manager") {
        return false;
    }
    return true;
}
export const formatAmount = (amount) => {
    amount = parseInt(amount);

    if (isNaN(amount)) {
        return 'Invalid amount';
    }
    return amount.toLocaleString('en-IN');
}

export const handleConfirmation = () => {
    const confirmed = window.confirm("Are you sure you want to proceed?");
    if (confirmed) {
        return true;
    }
    return false;
};

