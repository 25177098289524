import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { setMatchSelectionData } from "../../redux/slices/bookEventSlice";
import store from "../../redux/store";
import actions from "../../redux/actions";
import { isSuperAdmin, isRoleExits } from "../../utils";
import ScoreBoard from "../ScoreBoard";
import ScoreBoardTennis from "../ScoreBoard/ScoreBoardTennis";
import MarketPleaceBet from "../Models/MarketPleaceBet";
import { useParams } from "react-router-dom";

const Bookmaker = ({
  eventDetail,
  betLockMarket,
  betLockMarketStatus,
  userBookHandler,
  betBMEditHandler,
  betEventStatusHandler,
  EventId,
  sportsId,
}) => {
  var profitLossOnRunners = [];
  const { sportId } = useParams();

  const marketFareRate = useSelector(
    (state) => state.bookEvent.BetfareRetsMarketOdds
  );
  const bmFancyFareRate = useSelector(
    (state) => state.bookEvent.BetfareRetsBMAndFancyData?.BookMaker
  );
  const tossFareRate = useSelector(
    (state) => state.bookEvent.BetfareTossOddsData?.Result
  );
  const profitLossOnRunnersD = useSelector(
    (state) => state.bookEvent.profitLossOnRunnersData?.Result
  );
  const activeBMList = useSelector((state) => state.bookEvent.CustomeBMList);

  const [MarketId, setMarketId] = useState("");

  if (
    profitLossOnRunnersD !== undefined &&
    profitLossOnRunnersD.length > 0 &&
    profitLossOnRunnersD[0].book !== undefined
  ) {
    // profitLossOnRunners = profitLossOnRunnersD[0].book;
    profitLossOnRunners = profitLossOnRunnersD.flatMap((market) =>
      market.book.map((bookItem) => ({
        marketid: market.marketid,
        selectionId: bookItem.selectionId,
        marketType: bookItem.marketType,
        profitLoss: bookItem.profitLoss,
        userId: bookItem.userId,
      }))
    );
  }
  const selectMatchingRunner2 = () => marketFareRate || [];
  const selectMatchingRunner3 = () => bmFancyFareRate || [];
  const selectMatchingRunner4 = () => tossFareRate || [];

  const selectAllRunners = createSelector(
    [selectMatchingRunner2, selectMatchingRunner3, selectMatchingRunner4],
    (matchingRunner2, matchingRunner3, matchingRunner4) =>
      [].concat(matchingRunner2).concat(matchingRunner3).concat(matchingRunner4)
  );

  const placeBetHandler = (runner, marketType, id) => {
    setMarketId(id);
    store.dispatch(setMatchSelectionData({ runner, marketType }));
  };
  const allRunners = useSelector(selectAllRunners);

  activeBMList.forEach((activeItem) => {
    if (activeItem.IsCustome === true) {
      allRunners.push({
        marketId: activeItem.MarketId,
        status: activeItem.status,
        runners: activeBMList
          .map((runner) => {
            if (runner.MarketId === activeItem.MarketId) {
              return {
                selectionId: runner.selectionId,
                RunnerName: runner.RunnerName,
                status: runner.status,
                ex: {
                  availableToBack: [
                    {
                      price: runner.BackPrice1,
                      size: runner.BackSize1,
                    },
                  ],
                  availableToLay: [
                    {
                      price: runner.LayPrice1,
                      size: runner.LaySize1,
                    },
                  ],
                },
              };
            }
            return null;
          })
          .filter(Boolean),
      });
    }
  });

  const updatedAllRunners = allRunners?.map((bookmarklist) => {
    const matchingRunnelistItems = activeBMList?.filter(
      (item) =>
        item.IsCustome === true && item.MarketId === bookmarklist.marketId
    );

    if (matchingRunnelistItems && matchingRunnelistItems.length > 0) {
      const updatedRunners = bookmarklist.runners?.map((runner) => {
        const matchingRunnelistItem = matchingRunnelistItems.find(
          (item) => String(item.RunnerName) === String(runner.RunnerName)
        );

        if (matchingRunnelistItem) {
          return {
            ...runner,
            ex: {
              availableToBack: [
                {
                  price: matchingRunnelistItem.BackPrice1,
                  size: matchingRunnelistItem.BackSize1,
                },
              ],
              availableToLay: [
                {
                  price: matchingRunnelistItem.LayPrice1,
                  size: matchingRunnelistItem.LaySize1,
                },
              ],
              // ... other properties in ex if any
            },
            status: matchingRunnelistItem.status,
          };
        }

        return runner;
      });

      return {
        ...bookmarklist,
        runners: updatedRunners,
      };
    }

    return bookmarklist;
  });

  const marketBetList = (marketId) => {
    actions.getMarketBetList({
      marketId: marketId,
      fancyId: "0",
      MarketType: "MATCH_ODDS",
    });
  };

  return (
    <div>
      {Number(sportId) === 4 ? <ScoreBoard /> : <ScoreBoardTennis />}
      {eventDetail?.map((market, index) => (
        <>
          <div key={market.MarketId}>
            <MarketPleaceBet
              MarketId={MarketId}
              EventId={EventId}
              sportsId={sportsId}
            />
            <div className="tag-container white-tag-container py-1 d-flex justify-content-center align-items-center">
              <span className="tag-text text-white">{market?.MarketType}</span>
              {isRoleExits("match_position") && (
                <button
                  type="button"
                  className="btn btn-warning btn-sm text-white"
                  data-toggle="modal"
                  data-target="#bookModal"
                  style={{ fontSize: "12px" }}
                  onClick={() =>
                    userBookHandler(market.MarketName, market.MarketId)
                  }
                >
                  BOOK
                </button>
              )}
              {/* <button type="button" className="btn btn-danger btn-sm text-green mx-2" style={{ fontSize: '12px' }} onClick={() => betLockMarket(market)}>Bet Lock <i className={`ml-1 fas ${betLockMarketStatus ? "fa-unlock" : "fa-lock"}`} /></button> */}
              {isSuperAdmin() && (
                <button
                  type="button"
                  className={`btn ${
                    market.Liquidity == 0 ? "btn-success" : "btn-danger"
                  } btn-sm text-green mx-2`}
                  style={{ fontSize: "12px" }}
                  onClick={() => betLockMarket(market, market?.MarketId)}
                >
                  Bet Lock{" "}
                  <i
                    className={`ml-1 fas ${
                      market.Liquidity == 0 ? "fa-unlock" : "fa-lock"
                    }`}
                  />
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary btn-sm text-green"
                data-toggle="modal"
                data-target="#fancyBetListModal"
                style={{ fontSize: "12px" }}
                onClick={() => marketBetList(market.MarketId)}
              >
                Bet List{" "}
              </button>
              {isSuperAdmin() && (
                <a
                  role="button"
                  className="btn btn-success btn-sm text-green mx-2"
                  data-toggle="modal"
                  data-target="#marketPleaceBet"
                  onClick={() =>
                    placeBetHandler(
                      market?.runners,
                      market?.MarketType,
                      market?.MarketId
                    )
                  }
                >
                  PlaceBet
                </a>
              )}
              {market?.MarketType == "Bookmaker" &&
                isRoleExits("matchmanual") && (
                  <i
                    className="ml-1 fas fa-edit ml-auto m-1 p-1"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => betBMEditHandler(market.MarketId)}
                  ></i>
                )}
              {market?.MarketType == "MATCH_ODDS" && isSuperAdmin() && (
                <button
                  type="button"
                  className={`btn ${
                    market.EvBetStatus ? "btn-success" : "btn-danger"
                  } btn-sm text-green mx-2`}
                  style={{ fontSize: "12px" }}
                  onClick={() => betEventStatusHandler(market)}
                >
                  Event Lock{" "}
                  <i
                    className={`ml-1 fas ${
                      market.isBetLock ? "fa-unlock" : "fa-lock"
                    }`}
                  />
                </button>
              )}
            </div>

            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr className="td-tr-botton-border">
                    <th className="font-weight-bold" style={{ width: "50%" }}>
                      Min/Max {market?.MinStake}-{market?.MaxStake}
                    </th>
                    <th style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="back market-lay-back"
                        style={{ marginRight: "10px" }}
                      >
                        <span
                          className="font-weight-bold"
                          style={{ color: "white" }}
                        >
                          {" "}
                          Back{" "}
                        </span>
                      </div>
                      <div className="lay market-lay-back">
                        <span
                          className="font-weight-bold"
                          style={{ color: "white" }}
                        >
                          {" "}
                          Lay{" "}
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {market?.runners?.map((runner) => {
                    var matchingRunner2 = [];

                    const matchingRunner = updatedAllRunners?.find(
                      (runner2) => runner2?.marketId === runner?.marketId
                    );
                    // const matchingRunner2 = matchingRunner?.runners.find(runner2 => runner2?.selectionId.toString() === runner?.selectionId.toString());
                    if (market.MarketType === "Bookmaker") {
                      matchingRunner2 = matchingRunner?.runners.find(
                        (runner2) =>
                          runner2?.RunnerName.replace(".", "") ===
                          runner?.RunnerName.replace(".", "")
                      );
                    } else {
                      matchingRunner2 = matchingRunner?.runners.find(
                        (runner2) =>
                          runner2?.selectionId.toString() ===
                          runner?.selectionId.toString()
                      );
                    }

                    const plRunnerData = profitLossOnRunners?.find(
                      (runner2) =>
                        runner2?.selectionId.toString() ===
                          runner?.id.toString() &&
                        runner2?.marketType === market.MarketType
                    );

                    return (
                      <tr
                        key={runner.selectionId}
                        className="td-tr-botton-border"
                      >
                        <td className="font-weight-bold">
                          {runner.RunnerName} <br />
                          <span
                            style={{
                              color:
                                plRunnerData?.profitLoss < "0"
                                  ? "red"
                                  : "green",
                            }}
                          >
                            {plRunnerData?.profitLoss}
                          </span>
                        </td>
                        <td
                          style={{
                            display: "flex",
                            position: "relative",
                            justifyContent: "center",
                          }}
                        >
                          {market?.EvBetStatus ? (
                            matchingRunner2?.status !== "ACTIVE" && (
                              <div className="suspended-event">
                                <b className="text-red-900">
                                  {matchingRunner2?.status === "ACTIVE"
                                    ? matchingRunner2?.status
                                    : "SUSPENDED"}
                                </b>
                              </div>
                            )
                          ) : (
                            <div className="suspended-event">
                              <b className="text-red-900">SUSPENDED</b>
                            </div>
                          )}
                          <div
                            className="rate back market-lay-back "
                            style={{ marginRight: "10px" }}
                          >
                            <span className="font-weight-bold">
                              {market?.EvBetStatus
                                ? matchingRunner2?.ex.availableToBack[0]
                                    ?.price || "0"
                                : "0"}
                            </span>
                            <br />
                            {market.MarketType === "Bookmaker" ? (
                              <span> 0 </span>
                            ) : (
                              <span>
                                {market?.EvBetStatus
                                  ? matchingRunner2?.ex.availableToBack[0]
                                      ?.size || "0"
                                  : "0"}
                              </span>
                            )}
                          </div>
                          <div className="rate lay market-lay market-lay-back">
                            <span className="font-weight-bold">
                              {market?.EvBetStatus
                                ? matchingRunner2?.ex.availableToLay[0]
                                    ?.price || "0"
                                : "0"}
                            </span>
                            <br />
                            {market.MarketType === "Bookmaker" ? (
                              <span> 0 </span>
                            ) : (
                              <span>
                                {market?.EvBetStatus
                                  ? matchingRunner2?.ex.availableToLay[0]
                                      ?.size || "0"
                                  : "0"}
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default Bookmaker;
