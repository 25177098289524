import React, { useEffect, useState } from "react";
import actions from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import store from "../../redux/store";
import { setSelectionData } from "../../redux/slices/bookEventSlice";
import { isSuperAdmin, isRoleExits } from "../../utils";

const FancyBets = ({
  fancyprofitLossData,
  fancyOddsFareRate,
  setUpdateFancyStatusHandler,
  marketID,
  eventId,
  EvBetStatus,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the threshold as needed
  const [updatedFancyOddsFareRate, setUpdatedFancyOddsFareRate] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const excludedStrings = [
      "Total",
      "bhav",
      "by",
      ".1",
      ".2",
      ".3",
      ".4",
      ".5",
      ".6",
      "boundaries",
      "bahv",
    ];
    if (
      fancyprofitLossData &&
      fancyOddsFareRate &&
      fancyprofitLossData !== "No Data"
    ) {
      //debugger;
      const updatedFancyRate = fancyOddsFareRate?.map((runner) => {
        const matchingFancyData = fancyprofitLossData?.find(
          (item) => item.selectionId === runner.SelectionId
        );
        return matchingFancyData
          ? { ...runner, profitLoss: matchingFancyData.profitLoss }
          : runner;
      });

      const filteredData = updatedFancyRate.filter((item) => {
        return !excludedStrings.some((str) => item.RunnerName.includes(str));
      });
      setUpdatedFancyOddsFareRate(filteredData);
    } else {
      const filteredData = fancyOddsFareRate.filter((item) => {
        return !excludedStrings.some((str) => item.RunnerName.includes(str));
      });
      setUpdatedFancyOddsFareRate(filteredData);
    }
  }, [fancyprofitLossData, fancyOddsFareRate]);

  const fancyBetListHandler = (runner, type) => {
    setExpandedItem(null);
    actions.getMarketBetList({
      marketId: marketID,
      fancyId: runner.SelectionId,
      MarketType: type,
    });
  };

  const fancyEditHandler = (data) => {
    navigate("/fancy-edit", { state: { ...data, marketID, eventId } });
  };

  const placeBetHandler = (runner) => {
    setExpandedItem(null);
    store.dispatch(setSelectionData(runner));
  };

  const bookHandler = (runner) => {
    store.dispatch(setSelectionData(runner));
    setExpandedItem(null);
    actions.getFancyCurrent({
      marketId: marketID,
      selectionId: runner.SelectionId,
    });
  };

  const togglePopup = (selectionId) => {
    if (expandedItem === selectionId) {
      setExpandedItem(null); // Close the popup if it's already open
    } else {
      setExpandedItem(selectionId); // Open the popup
    }
  };

  return (
    <>
      <div className="tag-container white-tag-container py-1 align-items-center">
        <span className="tag-text text-white">Fancy</span>
        <button
          className="custom-grey-btn"
          onClick={() => navigate(`/fancysetting/${marketID}`)}
        >
          Fancy List
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="td-tr-botton-border">
              <th className="font-weight-bold" style={{ width: "50%" }}>
                Min/Max 10-1000000
              </th>
              <th style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="lay market-lay-back"
                  style={{ marginRight: "10px" }}
                >
                  <span className="font-weight-bold" style={{ color: "white" }}>
                    No
                  </span>
                </div>
                <div className="back market-lay-back">
                  <span className="font-weight-bold" style={{ color: "white" }}>
                    Yes
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {updatedFancyOddsFareRate?.map((runer) => {
              let runner = Object.assign({}, runer);

              if (!EvBetStatus) {
                debugger;
                runner.GameStatus = "SUSPENDED";
                runner.BackPrice1 = "-";
                runner.BackSize1 = "-";
                runner.LayPrice1 = "-";
                runner.LaySize1 = "-";
              }
              return (
                <tr
                  key={runner.SelectionId}
                  className="td-tr-botton-border"
                  style={{ position: "relative" }}
                >
                  <td
                    className="font-weight-bold"
                    style={{ verticalAlign: "middle" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="position-relative ms-2 d-inline-block">
                        <a
                          role="button"
                          onClick={() => togglePopup(runner.SelectionId)}
                        >
                          <i className="fas fa-ellipsis-v fa-2x text-gray-800 mr-2" />
                        </a>
                        <div
                          className={`fancy_info-popup collapse${
                            expandedItem === runner.SelectionId ? " show" : ""
                          }`}
                        >
                          <a
                            data-bs-toggle="collapse"
                            aria-expanded={expandedItem === runner.SelectionId}
                            aria-controls={runner.SelectionId}
                            className="close"
                            data-bs-target={`#${runner.SelectionId}`}
                            onClick={() => togglePopup(runner.SelectionId)}
                          >
                            x
                          </a>

                          <dl>
                            {isRoleExits("fancy_position") && (
                              <dd>
                                <a
                                  role="button"
                                  data-toggle="modal"
                                  data-target="#currentFancyModal"
                                  onClick={() => bookHandler(runner)}
                                >
                                  Book
                                </a>
                              </dd>
                            )}
                            {isRoleExits("fancy_position") && (
                              <dd>
                                <a
                                  role="button"
                                  data-toggle="modal"
                                  data-target="#fancyBetListModal"
                                  onClick={() =>
                                    fancyBetListHandler(runner, "FANCY")
                                  }
                                >
                                  FancyBetList
                                </a>
                              </dd>
                            )}
                            {isRoleExits("fancymanual") && (
                              <dd>
                                <a
                                  role="button"
                                  onClick={() => fancyEditHandler(runner)}
                                >
                                  FancyEdit
                                </a>
                              </dd>
                            )}
                            {isSuperAdmin() && (
                              <dd>
                                <a
                                  role="button"
                                  data-toggle="modal"
                                  data-target="#fancyPleaceBet"
                                  onClick={() => placeBetHandler(runner)}
                                >
                                  PlaceBet
                                </a>
                              </dd>
                            )}

                            {isSuperAdmin() && (
                              <dd>
                                <a
                                  role="button"
                                  onClick={() => {
                                    setUpdateFancyStatusHandler(
                                      runner.SelectionId
                                    );
                                    setExpandedItem(null);
                                  }}
                                >
                                  D-Active
                                </a>
                              </dd>
                            )}
                          </dl>
                        </div>
                      </div>
                      <div>
                        <span
                          className={
                            !isMobile
                              ? ""
                              : runner.RunnerName.length < 25
                              ? ""
                              : "marquee-text"
                          }
                        >
                          {runner.RunnerName}
                          <i
                            className="fas fa-exclamation-circle fa-1x text-gray-800 ml-1"
                            title={`Min/MAX ${runner.min}-${runner.max}`}
                          />
                        </span>
                        <br />
                        <span
                          style={{
                            color: runner.profitLoss < 0 ? "red" : "green",
                          }}
                        >
                          {runner.profitLoss}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={
                        runner?.GameStatus === "" ? "" : "suspended-event"
                      }
                    >
                      <b className="text-red-900">
                        {EvBetStatus === false
                          ? "SUSPENDED"
                          : runner?.GameStatus}
                      </b>
                    </div>
                    <div
                      className="rate lay market-lay market-lay-back"
                      style={{ marginRight: "10px" }}
                    >
                      <span className="font-weight-bold">
                        {runner?.LayPrice1 ? runner?.LayPrice1 : "0"}
                      </span>
                      <br />
                      <span>{runner?.LaySize1 ? runner?.LaySize1 : "0"}</span>
                    </div>
                    <div className="rate back market-lay-back">
                      <span className="font-weight-bold">
                        {runner?.BackPrice1 ? runner?.BackPrice1 : "0"}
                      </span>
                      <br />
                      <span>{runner?.BackSize1 ? runner?.BackSize1 : "0"}</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FancyBets;
