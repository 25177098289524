import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./screen/Dashboard";
import SignIn from "./screen/SignIn";
import ClientList from "./screen/Clinent/ClientList";
import ClientSearchList from "./screen/ClinentSearch/ClientSearchList";
import ClientFindList from "./screen/ClinentFind/ClinentFindList";
import AddClient from "./screen/Clinent/AddClient";
import AccountStatement from "./screen/Report/AccountStatement";
import ChipStatement from "./screen/Report/ChipStatement";
import MarketPL from "./screen/Report/MarketPL";
import ChipSummary from "./screen/Report/ChipSummary";
import UserReport from "./screen/Report/UserReport";
import MarketManagement from "./screen/MarketManagement";
import BlockMarket from "./screen/BlockMarket";
import BlockSports from "./screen/BlockSports";
import CreateMatch from "./screen/CreateMatch";
import CreateMatch2 from "./screen/CreateMatch2";
import ViewMatch from "./screen/ViewMatch";
import MyBookEvent from "./screen/MyBookEvent";
import EventBets from "./screen/EventBets";
import FancyEdit from "./screen/FancyEdit";
import FancyResult from "./screen/FancyResult";
import MarketResult from "./screen/MarketResult";
import BMEdit from "./screen/BMEdit";
import FancysSetting from "./screen/FancysSetting";
import ShowMatchBet from "./screen/Report/AccountStatement/ShowMatchBet";
import ProfitLossStatement from "./screen/Report/AccountStatement/ProfitLossStatement";
import UserLog from "./screen/Report/UserLog";
import SettlementSummary from "./screen/Report/ChipSummary/SettlementSummary";
import UserSetting from "./screen/Clinent/UserSetting";
import News from "./screen/News";
import EventPLTable from "./screen/Report/MarketPL/EventPLTable";
import GameControl from "./screen/GameControl";
import MarketPLBetTable from "./screen/Report/MarketPL/MarketPLBetTable";
import ClientStatements from "./screen/ClientStatements";
import BettingHistory from "./screen/BettingHistory";
import { isRoleExits, showForAllExceptManager, isSuperAdmin } from "./utils";
import OsmMap from "./components/OsmMap";
import ResetMarketDataWrapper from "./components/ResetMarketDataWrapper";
import DeletedBets from "./screen/Report/DeletedBets/DeletedBets";
import SuperBets from "./screen/Report/SuperBets/SuperBets";
import CasinoBets from "./screen/CasinoBets/CasinoBets";

function App() {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  return (
    <Router>
      {/* <ResetMarketDataWrapper> */}
        <Routes>
          <Route path="/" element={<Navigate to={isAuthenticated ? "/home" : "/login"} />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/home" element={isAuthenticated ? (<Home />) : (<Navigate to="/login" />)}/>
          <Route path="/create-match" element={isSuperAdmin() ||
            isRoleExits("create_match") ? (<CreateMatch />) : (<Navigate to="/home" />)} />
          <Route path="/view-match" element={showForAllExceptManager() ||
            isRoleExits("fancy_result") ||
            isRoleExits("addfancy") ||
            isRoleExits("create_fancy") ||
            isRoleExits("fancymanual") ? (<ViewMatch />) : (<Navigate to="/home" />)} />
          <Route path="/create-match2" element={<CreateMatch2 />} />
          <Route path="/add-client" element={<AddClient />} />
          <Route path="/clientsSearch/:pageIndex/:pageSize/:mid/:role" element={showForAllExceptManager() ||
            isRoleExits("coin_add") ||
            isRoleExits("coin_wid") ? (<ClientSearchList />) : (<Navigate to="/home" />)} />
          <Route path="/clients/:pageIndex/:pageSize/:mid/:role" element={showForAllExceptManager() ||
            isRoleExits("coin_add") ||
            isRoleExits("coin_wid") ? (<ClientList />) : (<Navigate to="/home" />)} />
          <Route path="/clientfind/:pageIndex/:pageSize/:mid/:role" element={showForAllExceptManager() ||
            isRoleExits("coin_add") ||
            isRoleExits("coin_wid") ? (<ClientFindList />) : (<Navigate to="/home" />)} />
          <Route path="/user-setting" element={<UserSetting />} />
          <Route path="/account-statement" element={<AccountStatement />} />
          <Route path="/client-statement/:uid" element={<ClientStatements />} />
          <Route path="/chip-statement" element={<ChipStatement />} />
          <Route path="/betting_history/:marketid/:userId/:uid" element={<BettingHistory />} />
          <Route path="/marketpl" element={showForAllExceptManager() ||
            isRoleExits("pl_by_sports") ? (<MarketPL />) : (<Navigate to="/home" />)} />
          <Route path="/profitLossEvent/:eventid/:uid/:type" element={<EventPLTable />} />
          <Route path="/chip-summary" element={<ChipSummary />} />
          <Route path="/user-report/:uid" element={showForAllExceptManager() ||
            isRoleExits("pl_by_user") ? (<UserReport />) : (<Navigate to="/home" />)} />
          <Route path="/market-management" element={<MarketManagement />} />
          <Route path="/block-sports" element={<BlockSports />} />
          <Route path="/block-market" element={<BlockMarket />} />
          <Route path="/my-book-event/:marketid/:eventId/:sportId" element={<MyBookEvent />} />
          <Route path="/eventbets/:eventid/:marketid" element={<EventBets />} />
          <Route path="/fancyresult/:marketid" element={<FancyResult />} />
          <Route path="/marketresult/:marketid" element={<MarketResult />} />
          <Route path="/fancy-edit" element={<FancyEdit />} />
          <Route path="/bmedit/:marketid" element={<BMEdit />} />
          <Route path="/fancysetting/:marketid" element={<FancysSetting />} />
          <Route path="/ShowMatchBet/:eventId/:userId" element={<ShowMatchBet />} />
          <Route path="/profit_loss_statement/:marketId/:userId" element={<ProfitLossStatement />} />
          <Route path="/settlement-summmary/:userId" element={<SettlementSummary />} />
          <Route path="/userlog" element={<UserLog />} />
          <Route path="/news" element={isSuperAdmin() ||
            isRoleExits("add_news") ||
            isRoleExits("delete_news") ? (<News />) : (<Navigate to="/home" />)} />
          <Route path="/game-control" element={showForAllExceptManager() ? (<GameControl />) : (<Navigate to="/home" />)} />
          <Route path="/market-pl-bet" element={<MarketPLBetTable />} />
          <Route path="/user-location" element={<OsmMap />} />
          <Route path="/delete-bets" element={<DeletedBets />} />
          <Route path="/super-bets" element={<SuperBets />} />
          <Route path="/casinobets" element={<CasinoBets />} />
        </Routes>
      {/* </ResetMarketDataWrapper> */}
    </Router>
  );
}

export default App;
