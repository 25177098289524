import React, { useEffect, useState } from "react";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";
import moment from "moment";
import ScreenLoader from "../../../components/ScreenLoader";
import { HiMiniPlusSmall } from "react-icons/hi2";
import { HiMiniMinusSmall } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import * as BsIcons from "react-icons/bs";

const UserReportTable = () => {
  let profitLossByUserData = useSelector(
    (state) => state.report.profitLossByUserSpots?.Result
  );
  let profitLossByUserSpotData = useSelector(
    (state) => state.report.profitLossByUser?.Result
  );
  let userlist = useSelector((state) => state.auth.userDataList?.Result);

  const logindata = JSON.parse(localStorage.getItem("loginUserDetails"));

  const [fromDate, setFromDate] = useState(
    moment()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [grandTotal, setGrandTotal] = useState({});
  const [loader, setLoader] = useState(false);
  const [openRow, setOpenRow] = useState(null);
  const { uid } = useParams();
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [userId, setUserId] = useState(null);
  const [type, setType] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (userlist && Array.isArray(userlist)) {
      setUserSuggestions(userlist);
    }
  }, [userlist]);

  const handleUserInputChange = (e) => {
    const { value } = e.target;
    setUserInput(value);
  };

  useEffect(() => {
    if (userInput.trim() !== "") {
      // actions.getUserList(userInput);
      actions.getUserSearchList(userInput);
    }
  }, [userInput]);

  const handleProfitLossByUser = async (userId) => {
    setLoader(true);
    // let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
    let data = {
      startDate: fromDate,
      endDate: toDate,
      uid: userId,
      type: type,
    };
    await actions.profitLossByUserAction(data);
    setLoader(false);
  };

  useEffect(() => {
    const userIdToUse = uid !== "null" ? uid : logindata?.uid;
    if (typeof userIdToUse === "string") {
      handleProfitLossByUser(userIdToUse);
    }
  }, [uid]);

  useEffect(() => {
    if (profitLossByUserData && Array.isArray(profitLossByUserData)) {
      const totals = {};

      for (const obj of profitLossByUserData) {
        for (const key in obj) {
          if (totals.hasOwnProperty(key)) {
            totals[key] += obj[key];
          } else {
            totals[key] = obj[key];
          }
        }
      }
      setGrandTotal(totals);
    }
  }, [profitLossByUserData]);

  const handleFromDateChange = (event) => {
    setFromDate(moment(event.target.value).format("YYYY-MM-DDTHH:mm"));
  };

  const handleToDateChange = (event) => {
    setToDate(moment(event.target.value).format("YYYY-MM-DDTHH:mm"));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let logindata = JSON.parse(localStorage.getItem("loginUserDetails"));
    let data = {
      type: type,
      startDate: fromDate,
      endDate: toDate,
      uid: userId !== null ? userId : logindata?.uid,
    };
    await actions.profitLossByUserAction(data);
    setLoader(false);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    // setFromDate(moment().format('YYYY-MM-DDTHH:mm'));
    // setToDate(moment().format('YYYY-MM-DDTHH:mm'));
    setLoader(true);
    setUserId(null);
    setType(1);
    setUserInput("");
    let logindata = JSON.parse(localStorage.getItem("loginUserDetails"));
    let data = {
      type: 1,
      startDate: moment().format("YYYY-MM-DDTHH:mm"),
      endDate: moment().format("YYYY-MM-DDTHH:mm"),
      uid: logindata?.uid,
    };
    await actions.profitLossByUserAction(data);
    setLoader(false);
  };

  const handleUsernameClicked = (uid) => {
    //console.log("userId !== null ? userId : uid", userId, uid);
    navigate(
      `/user-report/${encodeURIComponent(userId !== null ? userId : uid)}`
    );
    // let data = {
    //   uid,
    //   startDate: fromDate,
    //   endDate: toDate
    // }
    //actions.profitLossByUserAction(data);
  };

  const handleNestedTable = (uid) => {
    setOpenRow(uid === openRow ? null : uid);
    if (uid !== openRow) {
      let data = {
        uid,
        type: type,
        startDate: fromDate,
        endDate: toDate,
      };
      actions.getPlByUserSpotsAction(data);
    }
  };

  const handleUserSelection = (user) => {
    // setmarketPLReq((prevState) => ({ ...prevState, uid: user.uid }))
    // //console.log("logifd", user.uid);
    let data = {
      type: 2,
      uid: user.uid,
      startDate: fromDate,
      endDate: toDate,
    };
    actions.profitLossByUserAction(data);
    setUserId(user.uid);
    setType(2);
    setUserInput(user.username);
    setUserSuggestions([]);
  };

  return (
    <>
      {loader ? (
        <ScreenLoader />
      ) : (
        <div className="card shadow mb-4">
          <div className="card-body">
            {uid !== "null" && (
              <button className="back-btn mb-1" onClick={() => navigate(-1)}>
                {" "}
                <BsIcons.BsArrowReturnLeft /> Back
              </button>
            )}

            <div className="table-responsive">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper dt-bootstrap4"
              >
                {/* Start select date view*/}
                <div className="form-group d-flex flex-wrap">
                  <div className="col-md-2 col-sm-3">
                    <label>From:</label>
                    <input
                      type="datetime-local"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2 col-sm-3">
                    <label>To:</label>
                    <input
                      type="datetime-local"
                      value={toDate}
                      onChange={handleToDateChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2 col-sm-3">
                    <div className="form-group">
                      <label htmlFor="email">User:</label>
                      <input
                        type="text"
                        value={userInput}
                        onChange={handleUserInputChange}
                        className="form-control"
                      />
                      {userSuggestions?.length > 0 && (
                        <ul className="suggestion-list">
                          {userSuggestions?.map((user, index) => (
                            <li
                              key={index}
                              onClick={() => handleUserSelection(user)}
                            >
                              {user?.username}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-2 col-sm-3">
                  <label >Search By User Name</label>
                  <input
                    type="text"
                    className="form-control"
                  />
                </div> */}
                  <div className="col-md-3 col-sm-3">
                    <label>&nbsp;</label>
                    <div className="form-actions no-margin-bottom">
                      <button
                        onClick={handleSubmit}
                        className="custom-yellow-btn"
                        style={{ fontSize: "12px" }}
                      >
                        Submit
                      </button>
                      <button
                        onClick={handleReset}
                        className="custom-grey-btn"
                        style={{ fontSize: "12px" }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>

                <hr />
                {/* Start List of Table Account statement Component*/}
                <div className="">
                  <div className="">
                    <table className="table table-bordered dataTable">
                      <thead>
                        <tr className="">
                          <th className="col-desc text-no-wrap" colSpan="4">
                            &nbsp;
                          </th>
                          <th
                            className="col-desc text-no-wrap text-center"
                            colSpan="3"
                          >
                            MARKET
                          </th>
                          <th
                            className="col-desc text-no-wrap text-center"
                            colSpan="3"
                          >
                            COMMISION DENA
                          </th>
                          <th
                            className="col-desc text-no-wrap text-center"
                            colSpan="3"
                          >
                            COMMISION LENA
                          </th>
                          <th className="col-desc text-no-wrap" colSpan="2">
                            &nbsp;
                          </th>
                        </tr>
                        <tr className="">
                          <th className="col-desc text-no-wrap">USERNAME</th>
                          <th className="col-desc text-no-wrap">PROFIT/LOSS</th>
                          <th className="col-desc text-no-wrap">COMMISION</th>
                          <th className="col-desc text-no-wrap">TOTAL P&L</th>

                          <th className="col-desc text-no-wrap">ODDS</th>
                          <th className="col-desc text-no-wrap">BOOKMARKER</th>
                          <th className="col-desc text-no-wrap">FANCY</th>

                          <th className="col-desc text-no-wrap">BOOKMARKER</th>
                          <th className="col-desc text-no-wrap">FANCY</th>
                          <th className="col-desc text-no-wrap">TOTAL</th>

                          <th className="col-desc text-no-wrap">BOOKMARKER</th>
                          <th className="col-desc text-no-wrap">FANCY</th>
                          <th className="col-desc text-no-wrap">TOTAL</th>

                          <th className="col-desc text-no-wrap">OWN</th>
                          <th className="col-desc text-no-wrap">UPPERLINE</th>
                        </tr>
                      </thead>

                      <tbody className="">
                        {profitLossByUserData &&
                          Array.isArray(profitLossByUserData) &&
                          profitLossByUserData?.map((data, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td
                                  style={{
                                    color: "#4e73df",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    onClick={() =>
                                      handleUsernameClicked(data?.Uid)
                                    }
                                  >
                                    {data?.UserName}
                                  </span>{" "}
                                  <span
                                    onClick={() => handleNestedTable(data?.Uid)}
                                  >
                                    [
                                    {openRow === data?.Uid
                                      ? [<HiMiniMinusSmall />]
                                      : [<HiMiniPlusSmall />]}
                                    ]
                                  </span>
                                </td>
                                <td data-value={data?.Pl}>{data?.Pl}</td>
                                <td data-value={data?.downline}>
                                  {data?.downline}
                                </td>
                                <td data-value={data?.Totalpl}>
                                  {data?.Totalpl}
                                </td>

                                <td data-value={data?.Odds}>{data?.Odds}</td>
                                <td data-value={data?.BM}>{data?.BM}</td>
                                <td data-value={data?.Fany}>{data?.Fany}</td>

                                <td data-value={data?.BM_dena}>
                                  {data?.BM_dena}
                                </td>
                                <td data-value={data?.Fany_dena}>
                                  {data?.Fany_dena}
                                </td>
                                <td data-value={data?.total_dena}>
                                  {data?.total_dena}
                                </td>

                                <td data-value={data?.BM_lena}>
                                  {data?.BM_lena}
                                </td>
                                <td data-value={data?.Fany_lena}>
                                  {data?.Fany_lena}
                                </td>
                                <td data-value={data?.total_lena}>
                                  {data?.total_lena}
                                </td>

                                <td data-value={data?.my}>{data?.my}</td>
                                <td data-value={data?.Upline}>
                                  {data?.Upline}
                                </td>
                              </tr>
                              {openRow === data?.Uid && (
                                <tr>
                                  <td colSpan="17">
                                    <table
                                      className="table table-bordered dataTable "
                                      style={{ border: "2px solid black" }}
                                    >
                                      <thead>
                                        <tr className="">
                                          <th
                                            className="col-desc text-no-wrap"
                                            colSpan="4"
                                          >
                                            &nbsp;
                                          </th>
                                          <th
                                            className="col-desc text-no-wrap text-center"
                                            colSpan="3"
                                          >
                                            BOOKMARKER
                                          </th>
                                          <th
                                            className="col-desc text-no-wrap text-center"
                                            colSpan="4"
                                          >
                                            COMMISION DENA
                                          </th>
                                          <th
                                            className="col-desc text-no-wrap text-center"
                                            colSpan="4"
                                          >
                                            COMMISION LENA
                                          </th>
                                          <th
                                            className="col-desc text-no-wrap"
                                            colSpan="2"
                                          >
                                            &nbsp;
                                          </th>
                                        </tr>
                                        <tr className="">
                                          <th className="col-desc text-no-wrap">
                                            SPORTS
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            PROFIT/LOSS
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            COMMISION
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            TOTAL P&L
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            ODDS
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            BOOKMARKER
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            FANCY
                                          </th>

                                          <th className="col-desc text-no-wrap">
                                            BOOKMARKER
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            FANCY
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            TOTAL
                                          </th>

                                          <th className="col-desc text-no-wrap">
                                            BOOKMARKER
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            FANCY
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            TOTAL
                                          </th>

                                          <th className="col-desc text-no-wrap">
                                            OWN
                                          </th>
                                          <th className="col-desc text-no-wrap">
                                            UPPERLINE
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="">
                                        {profitLossByUserSpotData &&
                                          Array.isArray(
                                            profitLossByUserSpotData
                                          ) &&
                                          profitLossByUserSpotData?.map(
                                            (data, index) => (
                                              <tr key={index}>
                                                <td>
                                                  <strong>
                                                    {data?.Sports}
                                                  </strong>
                                                </td>
                                                <td data-value={data?.Pl}>
                                                  {data?.Pl}
                                                </td>
                                                <td data-value={data?.downline}>
                                                  {data?.downline}
                                                </td>
                                                <td data-value={data?.Totalpl}>
                                                  {data?.Totalpl}
                                                </td>

                                                <td data-value={data?.Odds}>
                                                  {data?.Odds}
                                                </td>
                                                <td data-value={data?.BM}>
                                                  {data?.BM}
                                                </td>
                                                <td data-value={data?.Fany}>
                                                  {data?.Fany}
                                                </td>

                                                <td data-value={data?.BM_dena}>
                                                  {data?.BM_dena}
                                                </td>
                                                <td
                                                  data-value={data?.Fany_dena}
                                                >
                                                  {data?.Fany_dena}
                                                </td>
                                                <td
                                                  data-value={data?.total_dena}
                                                >
                                                  {data?.total_dena}
                                                </td>

                                                <td data-value={data?.BM_lena}>
                                                  {data?.BM_lena}
                                                </td>
                                                <td
                                                  data-value={data?.Fany_lena}
                                                >
                                                  {data?.Fany_lena}
                                                </td>
                                                <td
                                                  data-value={data?.total_lena}
                                                >
                                                  {data?.total_lena}
                                                </td>

                                                <td data-value={data?.my}>
                                                  {data?.my}
                                                </td>
                                                <td data-value={data?.Upline}>
                                                  {data?.Upline}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        {}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        <tr className="bg-gray-400">
                          <td>
                            <b className="text-no-wrap">GRAND TOTAL</b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.Pl}>
                              {Math.round(grandTotal?.Pl)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.downline}>
                              {Math.round(grandTotal?.downline)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.Totalpl}>
                              {Math.round(grandTotal?.Totalpl)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.Odds}>
                              {Math.round(grandTotal?.Odds)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.BM}>
                              {Math.round(grandTotal?.BM)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.Fany}>
                              {Math.round(grandTotal?.Fany)}
                            </b>
                          </td>

                          <td>
                            <b data-value={grandTotal?.BM_dena}>
                              {Math.round(grandTotal?.BM_dena)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.Fany_dena}>
                              {Math.round(grandTotal?.Fany_dena)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.total_dena}>
                              {Math.round(grandTotal?.total_dena)}
                            </b>
                          </td>

                          <td>
                            <b data-value={grandTotal?.BM_lena}>
                              {Math.round(grandTotal?.BM_lena)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.Fany_lena}>
                              {Math.round(grandTotal?.Fany_lena)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.total_lena}>
                              {Math.round(grandTotal?.total_lena)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.my}>
                              {Math.round(grandTotal?.my)}
                            </b>
                          </td>
                          <td>
                            <b data-value={grandTotal?.Upline}>
                              {Math.round(grandTotal?.Upline)}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserReportTable;
