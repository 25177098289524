import axios from 'axios'
import { ACCOUNTSTATEMENTAPI, ACCOUNTBETHISTORYAPI, ACCOUNTFANCYBETHISTORYAPI } from './url';

class ReportServiceClass {

    async getAccountStatementService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${ACCOUNTSTATEMENTAPI}?uid=${data?.uid}&skipRec=${data?.skipRec}&type=${data?.type}&psize=${data?.psize}&startDate=${data?.startDate}&endDate=${data?.endDate}&sportsId=${data?.sportsId}`, {
            headers: headers,
        });
    }

    async getAccountBetHistoryService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${ACCOUNTBETHISTORYAPI}?marketId=${data.marketId}&role=${data.role}&userId=${data.userId}`, {
            headers: headers,
        });
    }
    async getAccountFancyBetHistoryService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${ACCOUNTFANCYBETHISTORYAPI}?marketId=${data.marketId}&userId=${data.userId}`, {
            headers: headers,
        });
    }

}



const ReportService = new ReportServiceClass();

export default ReportService;