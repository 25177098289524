import axios from "axios";
import { SAVENEWS, GETNEWSLIST, GETNEWSAPI } from "./url";

class NewsServiceClass {
  constructor() {
    let logindata = JSON.parse(localStorage.getItem("loginUserDetails"));
    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    this.axiosInstance = axios.create({
      headers: this.headers,
      timeout: 8000, // Timeout set to 5 seconds
    });
  }

  async getNewsListService() {
    return await this.axiosInstance.get(`${GETNEWSLIST}`);
  }

  async saveNewsService(data) {
    return await this.axiosInstance.post(`${SAVENEWS}`, data);
  }

  async GetNewsService() {
    return await this.axiosInstance.get(`${GETNEWSAPI}`);
  }
}

const NewsService = new NewsServiceClass();
export default NewsService;
