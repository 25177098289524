import MarketService from "../../services/bookEventService";
import { setCustomeBMList, setFancyActiveList,setfancyUndeclareList, saveBetfareRetsBMAndFancy, setScoreData, setTennisScoreData, saveBfRMarketOdddsOther, saveBfRTossOddds, setCustomeMarketData, setEventDetail, setFancyBetList, setFancyPostionData, setMatchBetData, setUseBookData, setCurrentFP, setProfitLossOnRunnersData, setBlockEventData, setBlockSportData, setEventFancyDetail } from "../slices/bookEventSlice";
import store from "../store";

export function getEventDetail(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.getEventDetailService(eventId).then((res) => {
            store.dispatch(setEventDetail(res.data.Result.MarketList))
            store.dispatch(setEventFancyDetail(res.data.Result.fancy))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function GetMatchedBetList(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetBetModelList(eventId).then((res) => {
            store.dispatch(setMatchBetData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function GetFancyPostionList(marketId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetFancyPostion(marketId).then((res) => {
            store.dispatch(setFancyPostionData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function updateFancyStauts(data) {
    return new Promise((resolve, rejact) => {
        MarketService.updateFancyStautsService(data).then((res) => {
            // store.dispatch(setFancyPostionData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function getBetfareTossOdds(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareTossOddsService(marketids).then((res) => {
            store.dispatch(saveBfRTossOddds(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function getBetfareRetsMarketAndOther(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareMarketAndOtherService(marketids).then((res) => {
            store.dispatch(saveBfRMarketOdddsOther(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function postBlockMarketAction(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postBlockMarketService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form postMarketSettingModelapi", error)
            rejact(error)
        })
    })
}

export function getUserBook(bookData) {
    return new Promise((resolve, rejact) => {
        MarketService.getUserBookService(bookData).then((res) => {
            store.dispatch(setUseBookData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function GetCustomeMarket(bookData) {
    return new Promise((resolve, rejact) => {
        MarketService.GetCustomeMarketService(bookData).then((res) => {
            store.dispatch(setCustomeMarketData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function postCustomeMarket(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postCustomeMarketService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form postMarketSettingModelapi", error)
            // rejact(error)
        })
    })
}

export function getBetfareRetsBMAndFancy(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareRetsBMAndFancyService(marketids).then((res) => {
            store.dispatch(saveBetfareRetsBMAndFancy(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function getMarketBetList(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketBetListService(data).then((res) => {
            store.dispatch(setFancyBetList(res.data?.Result))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function getExposerBetList(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getExposerBetListService(data).then((res) => {
        
            store.dispatch(setFancyBetList(res.data?.Result))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}


export function getMarketBetPLList(plData) {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketBetListPLService(plData).then((res) => {
            store.dispatch(setFancyBetList(res.data?.Result))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function postFancyBetAdmin(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postFancyBetAdminService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form postMarketSettingModelapi", error)
            // rejact(error)
        })
    })
}
export function postMarketBetAdmin(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postMarketBetAdminService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form postMarketSettingModelapi", error)
            // rejact(error)
        })
    })
}


export function getundeclareFancyList(MarketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getUndeclareFancyListService(MarketId).then((res) => {
            store.dispatch(setfancyUndeclareList(res.data.Result))
            
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}
export function getactiveFancyList(MarketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getActiveFancyListService(MarketId).then((res) => {
            store.dispatch(setFancyActiveList(res.data.Result))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function getCustomeBMList(MarketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getCustomeBMService(MarketId + "B").then((res) => {
            store.dispatch(setCustomeBMList(res.data.Result))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function postCustomeFancy(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postCustomeFancyService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            // rejact(error)
        })
    })
}

export function getFancyCurrent(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getFancyCurrentService(data).then((res) => {
            store.dispatch(setCurrentFP(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function GetProfitLossOnRunnersList(marketId, sportId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetProfitLossOnRunners(marketId, sportId).then((res) => {
            //console.log("GetProfitLossOnRunners", res)
            store.dispatch(setProfitLossOnRunnersData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            // rejact(error)
        })
    })
}

export function GetProfitLossOnRunnersEventList(eventID, sportId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetProfitLossOnRunnersEvent(eventID, sportId).then((res) => {
            //console.log("GetProfitLossOnRunnersEvrnt", res)
            store.dispatch(setProfitLossOnRunnersData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            // rejact(error)
        })
    })
}

export function GetDeleteUserBet(betData) {
    return new Promise((resolve, rejact) => {
        MarketService.GetDeleteUserBetService(betData).then((res) => {
            // store.dispatch(setMatchBetData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function GetScoreData(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetScoreDataService(eventId).then((res) => {
            store.dispatch(setScoreData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function GetBlockEventList(sportId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetBlockEventListService(sportId).then((res) => {
            store.dispatch(setBlockEventData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function GetBlockSportList() {
    return new Promise((resolve, rejact) => {
        MarketService.GetBlockSportListService().then((res) => {
            store.dispatch(setBlockSportData(res.data.Result))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}
export function GetTennisScore(data) {
    return new Promise((resolve, rejact) => {
        MarketService.GetTennisScoreService(data).then((res) => {
            store.dispatch(setTennisScoreData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function PostBlockSport(data) {
    return new Promise((resolve, rejact) => {
        MarketService.PostBlockSportService(data).then((res) => {
            // store.dispatch(setBlockSportData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function getCustomFancyById(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getCustomFancyByIdService(data).then((res) => {
             resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}

export function postDeleteMarketType(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postDeleteMarketService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}