import { clientList,clientNewList,clientFindList, saveAmount, saveUserSetting, saveUserRolesList, saveRoleByUser } from "../slices/clientSlice";
import ClientService from "../../services/clientService";
import store from "../store";

 
export function getClientList(data) {
    return new Promise((resolve, rejact) => {
        ClientService.getClientListService(data).then((res) => {
            store.dispatch(clientList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form client list api", error)
        })
    })
}
export function getClientNewList(data) {
    return new Promise((resolve, rejact) => {
        ClientService.getClientNewListService(data).then((res) => {
            store.dispatch(clientNewList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form client list api", error)
        })
    })
}export function getClientFindList(data) {
    return new Promise((resolve, rejact) => {
        ClientService.getClientFindListService(data).then((res) => {
            store.dispatch(clientFindList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form client list api", error)
        })
    })
}
export function updateClientStatus(data) {
    return new Promise((resolve, rejact) => {
        ClientService.updateClientStatusService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form updateClientStatus api", error)
        })
    })
}
export function createClientStatus(data) {
    return new Promise((resolve, rejact) => {
        ClientService.createClientStatusService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form createClientStatus api", error)
        })
    })
}

export function getAmountAction(data) {
    return new Promise((resolve, rejact) => {
        ClientService.getAmountService(data).then((res) => {
            store.dispatch(saveAmount(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form getAmountService api", error)
        })
    })
}

export function saveAmountAction(data) {
    return new Promise((resolve, rejact) => {
        ClientService.saveAmountService(data).then((res) => {
            resolve({ response: res.data });
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form saveAmountService api", error)
        })
    })
}
export function saveUserSettingAction(data) {
    return new Promise((resolve, rejact) => {
        ClientService.saveUserSettingService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form saveUserSetting api", error)
        })
    })
}
export function saveAllUserSettingAction(data) {
    return new Promise((resolve, rejact) => {
        ClientService.saveAllUserSettingService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form saveAllUserSetting api", error)
        })
    })
}
export function userSettingAction(userId) {
    return new Promise((resolve, rejact) => {
        ClientService.userSettingService(userId).then((res) => {
            store.dispatch(saveUserSetting(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form userSettingService api", error)
        })
    })
}

export function getAllRoles() {
    return new Promise((resolve, rejact) => {
        ClientService.getAllRolesService().then((res) => {
            store.dispatch(saveUserRolesList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form all role list api", error)
        })
    })
}

export function getRoleByUser(uid) {
    return new Promise((resolve, rejact) => {
        ClientService.getRoleByUserService(uid).then((res) => {
            store.dispatch(saveRoleByUser(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form get role by user api", error)
        })
    })
}

export function saveUserRole(data) {
    return new Promise((resolve, rejact) => {
        ClientService.saveUserRoleService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form save role api", error)
        })
    })
}

