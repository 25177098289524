import { createSlice } from "@reduxjs/toolkit";

const logindata = JSON.parse(localStorage.getItem("loginUserDetails"));

const initialState = {
    userData: {},
    isLogin: false,
    userDataList: [],
    uuid: logindata?.uid || null,
    balanceDetails: {
        "CreditLimit": 0,
        "PL": 0,
        "Balance": 0,
        "Exposure": 0,
        "Cash": 0,
        "MainBalance": 0,
        "MyShare": 100
    }
};

const authSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        saveUserData: (state, action) => {
            state.userData = action.payload
            state.isLogin = true
        },
        userList: (state, action) => {
            state.userDataList = action.payload
        },
        setUuid: (state, action) => {
            state.uuid = action.payload;
        },
        saveBalance: (state, action) => {
            state.balanceDetails = action.payload;
        },
    }
})

export const { saveUserData, userList, setUuid, saveBalance } = authSlice.actions;
export default authSlice.reducer
