import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleStyle } from "../../redux/slices/styleSlice";
import * as FcIcons from "react-icons/fc";
import {
  isRoleExits,
  isSuperAdmin,
  showForAllExceptManager,
} from "../../utils";

const Sidebar = () => {
  const style = useSelector((state) => state?.style?.style);
  const dispatch = useDispatch();

  const changeStyle = () => {
    dispatch(toggleStyle());
  };

  return (
    <div>
      <ul className={style} id="accordionSidebar">
        {/* Sidebar - Brand */}
        <div
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="index.html"
        >
          <div className="sidebar-brand-text mx-3">
            <a href="/home">
              <img src="/img/logo.png" alt="logo" className="w-100" />
            </a>
          </div>
        </div>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item">
          <NavLink className="nav-link" activeclassname="active" to="/home">
            <FcIcons.FcBarChart className="mr-2" />
            <span>Dashboard</span>
          </NavLink>
        </li>
        {(showForAllExceptManager() ||
          isRoleExits("coin_add") ||
          isRoleExits("coin_wid")) && (
          <>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/clients/0/10/${encodeURIComponent(
                  JSON.parse(localStorage.getItem("loginUserDetails"))?.uid
                )}/${
                  JSON.parse(localStorage.getItem("loginUserDetails"))?.Role
                }`}
              >
                <FcIcons.FcManager className="mr-2" />
                <span>Clients List</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/clientssearch/0/10/${encodeURIComponent(
                  JSON.parse(localStorage.getItem("loginUserDetails"))?.uid
                )}/${
                  JSON.parse(localStorage.getItem("loginUserDetails"))?.Role
                }`}
              >
                <FcIcons.FcSearch className="mr-2" />
                <span>Clients Search List</span>
              </NavLink>
            </li>{" "}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/clientfind/0/10/${encodeURIComponent(
                  JSON.parse(localStorage.getItem("loginUserDetails"))?.uid
                )}/${
                  JSON.parse(localStorage.getItem("loginUserDetails"))?.Role
                }`}
              >
                <FcIcons.FcSearch className="mr-2" />
                <span>Clients Find</span>
              </NavLink>
            </li>
          </>
        )}

        {(isSuperAdmin() || isRoleExits("create_match")) && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/create-match">
              {/* <i className="fas fa-fw fa-chart-area" /> */}
              <FcIcons.FcAddImage className="mr-2" />
              <span>Create Match</span>
            </NavLink>
          </li>
        )}
        {(isSuperAdmin() || isRoleExits("create_match")) && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/create-match2">
              {/* <i className="fas fa-fw fa-chart-area" /> */}
              <FcIcons.FcAddImage className="mr-2" />
              <span>Create Match 2</span>
            </NavLink>
          </li>
        )}

        {/* Nav Item - Block Market */}
        {(showForAllExceptManager() ||
          isRoleExits("fancy_result") ||
          isRoleExits("fancymanual") ||
          isRoleExits("addfancy") ||
          isRoleExits("create_fancy")) && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/view-match">
              <FcIcons.FcViewDetails className="mr-2" />
              <span>View Match</span>
            </NavLink>
          </li>
        )}
        {/* Nav Item - Game Control */}
        {showForAllExceptManager() && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/game-control">
              <FcIcons.FcRules className="mr-2" />
              <span>Game Control</span>
            </NavLink>
          </li>
        )}
         <li className="nav-item">
            <NavLink className="nav-link" to="/casinobets">
              <FcIcons.FcRules className="mr-2" />
              <span>Live Casino</span>
            </NavLink>
          </li>
        {(showForAllExceptManager() ||
          isRoleExits("pl_by_user") ||
          isRoleExits("pl_by_sports")) && (
          <li className="nav-item">
            <div
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#reports"
              aria-expanded="true"
              aria-controls="reports"
            >
              <FcIcons.FcFile className="mr-2" />
              <span>Reports</span>
            </div>
            <div
              id="reports"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <NavLink to="/account-statement" className="collapse-item">
                  Account Statement
                </NavLink>

                {(showForAllExceptManager() || isRoleExits("pl_by_sports")) && (
                  <NavLink to="/marketpl" className="collapse-item">
                    Market P/L
                  </NavLink>
                )}
                {(showForAllExceptManager() || isRoleExits("pl_by_user")) && (
                  <NavLink to="/user-report/null" className="collapse-item">
                    Profil Loss User Wise
                  </NavLink>
                )}
                {isSuperAdmin() && (
                  <>
                    <NavLink to="/userlog" className="collapse-item">
                      User Log
                    </NavLink>
                    <NavLink to="/delete-bets" className="collapse-item">
                      Delete Bets
                    </NavLink>
                    <NavLink to="/super-bets" className="collapse-item">
                      Super Bets
                    </NavLink>
                  </>
                )}
              </div>
            </div>
          </li>
        )}

        {(isSuperAdmin() ||
          isRoleExits("add_news") ||
          isRoleExits("delete_news")) && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/news">
              <FcIcons.FcNews className="mr-2" />
              <span>News</span>
            </NavLink>
          </li>
        )}

        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={changeStyle}
          />
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
