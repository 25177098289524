import axios from 'axios';
import {GETCASINOBET, PROFITLOSSBYUSER, ACCOUNTSTATEMENT, MARKETSHARINGDETAIL, OVERALLPANDL, GETCHIPSUMMARY, PLBYUSERSPOTS, PROFITLOSSEVENT, PROFITLOSSSPORTS, SETTLEMENT, GETSETTLEMENTSUMMARY, GETUSERLOG, GETSHARINGDETAILS,GETDELETEBET,GETEVENTNAME,GETSUPERBET } from './url';


class ReportServiceClass {
    async profitLossByUserService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        //   uid: decodeURIComponent(userId)

        return await axios.get(`${PROFITLOSSBYUSER}?startDate=${data.startDate}&endDate=${data.endDate}&uid=${encodeURIComponent(data.uid)}&type=${data.type}`, {
            headers: headers,
        });
    }
    async getPlByUserSpotsService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${PLBYUSERSPOTS}?startDate=${data.startDate}&endDate=${data.endDate}&uid=${encodeURIComponent(data.uid)}&type=${data.type}`, {
            headers: headers,
        });
    }
    async accountStatementService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${ACCOUNTSTATEMENT}?uid=${logindata?.uid}&skipRec=${data?.skipRec}&type=${data?.type}&startDate=${data?.startDate}&endDate=${data?.endDate}&creditType=${data?.creditType}&sportsId=${data?.sportsId}`, {
            headers: headers,
        });
    }
    async marketSharingDetailsService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${MARKETSHARINGDETAIL}?marketId=${data?.marketId}&userId=${data?.userId}`, {
            headers: headers,
        });
    }
    async overAllPandLService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${OVERALLPANDL}?marketId=${data?.marketId}&userId=${data?.userId}&role=${logindata?.Role}`, {
            headers: headers,
        });
    }
    async chipSummaryService(userId) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${GETCHIPSUMMARY}?userId=${userId}`, {
            headers: headers,
        });
    }
    async settlementService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${SETTLEMENT}?userId=${data?.userId}&amount=${data?.amount}&type=${data?.type}&remarks=${data?.remarks}&mpin=${data?.pin}`, {
            headers: headers,
        });
    }
    async settlementSummaryService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${GETSETTLEMENTSUMMARY}?userId=${data?.userId}&skipRec=${data?.skipRec}`, {
            headers: headers,
        });
    }

    async userLogService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${GETUSERLOG}?uid=${encodeURIComponent(data?.uid)}&psize=${data?.psize}&skipRec=${data?.skipRec}&startDate=${data?.startDate}&endDate=${data?.endDate}`, {
            headers: headers,
        });
    }
   
    


    async getProfitLossSportsService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const uid = data?.uid === null || data?.uid === undefined ? logindata?.uid : data?.uid;
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${PROFITLOSSSPORTS}?uid=${encodeURIComponent(uid)}&psize=${data?.psize}&skipRec=${data?.skipRec}&startDate=${data?.startDate}&endDate=${data?.endDate}&sportsId=${data?.sportsId}&type=${data?.type}`, {
            headers: headers,
        });
    }
    async getProfitLossEventService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${PROFITLOSSEVENT}?uid=${data?.uid}&Eventid=${data?.eventid}&type=${data?.type}`, {
            headers: headers,
        });
    }

    async GetSharingDetailsService(data) {
        let jsonData = JSON.parse(localStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jsonData?.Token}`
        };

        return await axios.get(`${GETSHARINGDETAILS}?marketId=${data.marketId}&uid=${data?.uid === null || data?.uid === undefined ? jsonData.uid : data?.uid}&MarketType=${data.marketType}&fancyid=${data.fancyId}`, {
            headers: headers,
        });
    }
    async deleteBetLisService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        
        return await axios.get(`${GETDELETEBET}?EVENTID=${data?.eventid}`, {
            headers: headers,
        });
    }
    async eventNameLisService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        
        return await axios.get(`${GETEVENTNAME}?type=${data}`, {
            headers: headers,
        });
    }
    async superBetLisService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        
        return await axios.get(`${GETSUPERBET}?EVENTID=${data?.eventid}`, {
            headers: headers,
        });
    }
    async casinoBetLisService(data) {
        let logindata = JSON.parse(localStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        
        return await axios.get(`${GETCASINOBET}`, {
            headers: headers,
        });
    }
}


const ReportService = new ReportServiceClass();
export default ReportService;
