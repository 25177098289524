import React, { useEffect, useState } from "react";
import actions from "../../../redux/actions";
import moment from "moment";
import { Link } from "react-router-dom";

const AccountStatementTable = ({ data, fromDate, toDate, totalrow, type }) => {
  const [accountStatement, setAccountStatement] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [accountStatementReq, setAccountStatementReq] = useState({
    skipRec: 0,
    uid: encodeURIComponent(
      JSON.parse(localStorage.getItem("loginUserDetails"))?.uid
    ),
    creditType: "All",
    sportsId: 0,
  });

  useEffect(() => {
    setAccountStatement(data);
  }, [data]);

  useEffect(() => {
    setAccountStatementReq((prevState) => ({
      ...prevState,
      startDate: fromDate,
      endDate: toDate,
      type,
    }));
  }, [fromDate, toDate, type]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalrow / 10));
  }, [totalrow]);

  const handlePageNoChange = (newPageNo) => {
    setAccountStatementReq((prevState) => ({
      ...prevState,
      skipRec: newPageNo,
    }));
    let data = { ...accountStatementReq, skipRec: newPageNo };
    actions.accountStatementAction(data);
  };

  const DynamicPagination = (number) => {
    const maxPagesToShow = 4; // Maximum number of pages to show
    const startPage = Math.max(
      1,
      Math.min(number - maxPagesToShow + 1, accountStatementReq?.skipRec + 1)
    ); // Determine the start page

    const divs = [];

    for (
      let i = startPage;
      i < startPage + maxPagesToShow && i <= number;
      i++
    ) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${
              accountStatementReq?.skipRec + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setAccountStatementReq((prevState) => ({
                ...prevState,
                skipRec: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <div>
      <table
        className="table table-bordered dataTable"
        id="dataTable"
        width="100%"
        cellSpacing={0}
        role="grid"
        aria-describedby="dataTable_info"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th className="col-sno">S.No.</th>
            <th className="col-date">Name</th>
            <th className="col-desc">Description</th>
            <th className="col-credit">Credit</th>
            <th className="col-debit">Debit</th>
            <th className="col-balance">Commission</th>
            <th className="col-balance">Points</th>
            <th className="col-balance">Remarks</th>
            <th className="col-date">Date</th>
          </tr>
        </thead>
        <tbody>
          {accountStatement &&
            Array.isArray(accountStatement) &&
            accountStatement?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.UserName}</td>
                <td style={{ textWrap: "nowrap" }}>
                  <Link to={`/ShowMatchBet/${item?.EventId}/1`}>
                    {item?.Discription}
                  </Link>
                </td>
                <td className={item?.Credit < 0 ? "green-text" : "red-text"}>
                  {item?.Credit < 0 ? -1 * item?.Credit : item?.Credit}
                </td>
                <td className={item?.Debit < 0 ? "green-text" : "red-text"}>
                  {item?.Debit < 0 ? -1 * item?.Debit : item?.Debit}
                </td>
                <td className={item?.Com < 0 ? "green-text" : "red-text"}>
                  {item?.Com < 0 ? -1 * item?.Com : item?.Com}
                </td>
                <td className={item?.Balance < 0 ? "green-text" : "red-text"}>
                  {item?.Balance < 0 ? -1 * item?.Balance : item?.Balance}
                </td>
                <td>{item?.Remark}</td>
                <td style={{ textWrap: "nowrap" }}>
                  {" "}
                  {moment(item?.Date).format("DD-MM-YYYY h:mm:ss A")}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {accountStatement &&
        Array.isArray(accountStatement) &&
        accountStatement?.length > 0 && (
          <div className="row m-1">
            <div className="col-sm-12 col-md-5">
              <div
                className="dataTables_info"
                id="dataTable_info"
                role="status"
                aria-live="polite"
              >
                Total Page : {totalPage}
              </div>
            </div>
            <div className="col-sm-12 col-md-7">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                <ul className="pagination d-flex justify-content-end">
                  <li
                    className="paginate_button page-item previous disabled"
                    id="dataTable_previous"
                  >
                    <button
                      className="pagination-action-btn"
                      disabled={
                        accountStatementReq?.skipRec === 0 ? true : false
                      }
                      onClick={() => {
                        handlePageNoChange(accountStatementReq?.skipRec - 1);
                      }}
                    >
                      Previous
                    </button>
                  </li>
                  {DynamicPagination(totalPage)}
                  <li
                    className="paginate_button page-item next"
                    id="dataTable_next"
                  >
                    <button
                      className="pagination-action-btn"
                      disabled={
                        accountStatementReq?.skipRec === totalPage - 1
                          ? true
                          : false
                      }
                      onClick={() => {
                        handlePageNoChange(accountStatementReq?.skipRec + 1);
                      }}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default AccountStatementTable;
