import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import LiveTV from "../../components/LiveTV/LiveTV";
import Bookmaker from "../../components/Bookmaker/Bookmaker";
import FancyBets from "../../components/FancyBets/FancyBets";
import FancyUnDeclare from "../../components/FancyUnDeclare/FancyUnDeclare";
import AllBetList from "../../components/AllBetList/AllBetList";
import Footer from "../../components/footer";
import LogoutModel from "../../components/Models/LogoutModel";
import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import BookModal from "../../components/Models/BookModal";
import {
  setEventDetail,
  setUserBookName,
  /**********MultitimeApi**********/
  saveBetfareRetsBMAndFancy,
  saveBfRMarketOdddsOther,
  saveBfRTossOddds,
  setFancyActiveList,
  setCustomeBMList,
  setMatchBetData,
  setFancyPostionData,
} from "../../redux/slices/bookEventSlice";
import store from "../../redux/store";
import FancyBetListModal from "../../components/Models/FancyBetListModal";
import FancyPleaceBet from "../../components/Models/FancyBet";
import MarketPleaceBet from "../../components/Models/MarketPleaceBet";
import {
  startConnection,
  stopConnection,
  subscribeToEvent,
} from "../../services/SignalRService";
import FancyCurrentPostionModal from "../../components/Models/FancyCurrentPostionModal";
import { handleConfirmation, isRoleExits } from "../../utils";
import $ from "jquery";
import {
  GETBETFARETOSSAPI,
  GETFANCYPOSTIONAPI,
  GETUSERBETMODELAPI,
  GETACTIVEFANCYAPI,
  GETCUSTOMEBMAPI,
 
} from "../../services/url";

const MyBookEvent = () => {
  const { eventId, sportId, marketid } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [betLockMarketStatus, setBetLockMarketStatus] = useState(true);
  const navigate = useNavigate();
  const [marketID, setmarketID] = useState(marketid);

  const eventDetail = useSelector((state) => state.bookEvent.eventDetailData);
  const fancySetting = useSelector(
    (state) => state.bookEvent.eventDetailfancyData
  );
  //bookmakerfancy
  const fancyOddsFareRate = useSelector(
    (state) => state.bookEvent.BetfareRetsBMAndFancyData?.fancy
  );
  const fancyprofitLossData = useSelector(
    (state) => state.bookEvent.fancyPostionData?.Result
  );

  const activeFancyList = useSelector(
    (state) => state.bookEvent.fancyActiveList
  ); //fancy

  const UnDeclareFancyList = useSelector(
    (state) => state.bookEvent.fancyUndeclareList
  ); //fancy

  const BetfairTimer = useRef(null);
  const bmfancyTimer = useRef(null);
  const TossTimer = useRef(null);
  const ActiveFancyTimer = useRef(null);
  const CustomeBMTimer = useRef(null);
  const BetListTimer = useRef(null);
  const fancyPostionTimer = useRef(null);
  var fancyCount = 0;
  var [isTimer, setisTimer] = useState(1)
 
  useEffect(() => {
    setisTimer (1)
    actions.getEventDetail(eventId);
    return () => {
      isTimer = 0;
      window.location.reload();
    };
  }, [eventId]);

  //bookmaker and betfair and toss
  useEffect(() => {
    setisTimer (1)
    // Fetch data and set marketID based on eventDetail
    if (eventDetail && eventDetail.length > 0 && eventDetail !== undefined) {
      const marketids = eventDetail.map((market) =>
        market?.MarketId.replace(/[TB]+$/, "")
      );
      const uniqueMarketids = new Set(marketids);
      const queryString = [...uniqueMarketids].join(",");

      if (marketID !== undefined) {
        var MID = marketID.replace("B", "");
        setmarketID(MID);
        if (sportId === "4") {
          GetBMFancyData();
          var toss = marketids.find((x) => x == MID + "T");
          if (toss) GetTossData();
        }
        GetBetfairData(queryString);
      }
    }
    setisTimer (1)
    return () => {
      setisTimer (0)
      if (BetfairTimer.current) {
        clearTimeout(BetfairTimer.current);
      }
      if (bmfancyTimer.current) {
        clearTimeout(bmfancyTimer.current);
      }
      if (TossTimer.current) {
        clearTimeout(TossTimer.current);
      }
    };
  }, [eventDetail]);
  // FancyPostion and betlist
  useEffect(() => {
    if (sportId === "4") {
      if (isRoleExits("fancy_position")) {
        GetFancyPostionList();
      }
    }
    if (isRoleExits("match_position") || isRoleExits("fancy_position")) {
      GetMatchedBetList();
    }
    return () => {
      if (BetListTimer.current) {
        clearTimeout(BetListTimer.current);
      }
      if (fancyPostionTimer.current) {
        clearTimeout(fancyPostionTimer.current);
      }
    };
  }, [marketID]);

  // custome markets
  useEffect(() => {
    if (sportId === "4") {
      GetActiveFancyData();
      GetCustomBMData();
    }
    return () => {
      if (ActiveFancyTimer.current) {
        clearTimeout(ActiveFancyTimer.current);
      }
      if (CustomeBMTimer.current) {
        clearTimeout(CustomeBMTimer.current);
      }
    };
  }, [marketID]);

  var filteredMainlist = [];
  var filterUndeclareList = [];

  if (fancySetting?.IsFancyActive) {
   
    if (fancyOddsFareRate)
      filteredMainlist = JSON.parse(JSON.stringify(fancyOddsFareRate));
    if (filteredMainlist) {
      filteredMainlist.forEach((item) => {
        item.min = fancySetting?.SessionMinStake;
        item.max = fancySetting?.SessionMaxStake;
      });
    }
    if (
      fancyOddsFareRate &&
      typeof fancyOddsFareRate[Symbol.iterator] === "function"
    ) {
      activeFancyList.forEach((activelistItem) => {
        const index = filteredMainlist.findIndex(
          (item) => item.SelectionId === activelistItem.SelectionId
        );

        // Check if SelectionId is not present in filteredMainlist
        if (!filteredMainlist.some((item) => item.SelectionId === activelistItem.SelectionId ) ) {
          // Push the entire activelistItem to filteredMainlist
          if (activelistItem.isCustome) 
             filteredMainlist.push(activelistItem);
        } else {
          // Find the index of the matching item in filteredMainlist
          if (activelistItem.isCustome) {
            debugger;
            // Replace the values of the matching item in filteredMainlist
            filteredMainlist[index] = {
              ...filteredMainlist[index],
              LayPrice1: activelistItem.LayPrice1,
              LaySize1: activelistItem.LaySize1,
              BackPrice1: activelistItem.BackPrice1,
              BackSize1: activelistItem.BackSize1,
              GameStatus: activelistItem.GameStatus,
              max: activelistItem.max,
              min: activelistItem.min,
            };
          } else {
            if (!activelistItem.isActive) {
              const item = filteredMainlist.findIndex(
                (item) => item.SelectionId === activelistItem.SelectionId
              );
              filteredMainlist.splice(item, 1);
            } else {
              filteredMainlist[index] = {
                ...filteredMainlist[index],
                max: activelistItem.max,
                min: activelistItem.min,
              };
            }
          }
        }
      });
    } else {
      //console.log("fancyOddsFareRate is not defined or not iterable.");
    }
  } else {
    if (
      fancyOddsFareRate &&
      typeof fancyOddsFareRate[Symbol.iterator] === "function"
    ) {
      activeFancyList.forEach((activelistItem) => {
        // Check if SelectionId is not present in filteredMainlist

        if (
          !fancyOddsFareRate.some(
            (item) =>
              item.SelectionId === activelistItem.SelectionId &&
              activelistItem.isCustome
          )
        ) {
          // Push the entire activelistItem to filteredMainlist
          if (activelistItem.isCustome) {
            filteredMainlist.push(activelistItem);
          }
        }
        // else {
        if (activelistItem.isActive) {
          var item = fancyOddsFareRate.find(
            (item) => item.SelectionId === activelistItem.SelectionId
          );
          if (item) {
            Object.freeze(item);
            const objCopy = { ...item }; // 👈️ create copy
            objCopy.max = activelistItem.max;
            objCopy.min = activelistItem.min;
            filteredMainlist.push(objCopy);
          }
        }
        // Find the index of the matching item in filteredMainlist
        if (activelistItem.isCustome) {
          const index = filteredMainlist.findIndex(
            (item) => item.SelectionId === activelistItem.SelectionId
          );
          // Replace the values of the matching item in filteredMainlist

          filteredMainlist[index] = {
            ...filteredMainlist[index],
            LayPrice1: activelistItem.LayPrice1,
            LaySize1: activelistItem.LaySize1,
            BackPrice1: activelistItem.BackPrice1,
            BackSize1: activelistItem.BackSize1,
            GameStatus: activelistItem.GameStatus,
            max: activelistItem.max,
            min: activelistItem.min,
          };
        }
        //  }
      });
    } else {
      //console.log("fancyOddsFareRate is not defined or not iterable.");
    }
  }

  // undeclate List

  var filterUndeclareList = [];
  if (UnDeclareFancyList) {
    UnDeclareFancyList.forEach((unditem) => {
      if (
        !filteredMainlist.some(
          (item) => item.SelectionId === unditem.SelectionId
        )
      ) {
        filterUndeclareList.push(unditem);
      }
    });
  }

  useEffect(() => {
    startConnection();

    subscribeToEvent("GetActiveSession", (message) => {
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.Marketid === marketID) {
        store.dispatch(setFancyActiveList(data.fancyList));
      }
    });

    subscribeToEvent("GetActiveBM", (message) => {
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.Marketid === marketID + "B") {
        store.dispatch(setCustomeBMList(data.BMList));
      }
    });
    subscribeToEvent("BetPlacedMessage", (message) => {
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.EventId === eventId) {
        var marketid = data.MarketId;
        var mktype = data.MarketType;
        var BMID = marketid.replace("B", "");
        
      }
    });

    // Don't forget to stop the connection when the component is unmounted
    return () => {
      stopConnection();
    };
  }, [marketID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const delayInSeconds = 2;

  useEffect(() => {
    if (isRoleExits("match_position"))
      actions.GetProfitLossOnRunnersEventList(eventId, sportId);
    const intervalId = setInterval(() => {
      if (isRoleExits("match_position"))
        actions.GetProfitLossOnRunnersEventList(eventId, sportId);
    }, 4 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventId]);

  

  const betEventStatusHandler = async (MarketData) => {
    if (handleConfirmation() && MarketData?.EvBetStatus !== undefined) {
      const status = MarketData.EvBetStatus ? 0 : 1;
      await actions.matchControlAction(eventId, status, 2, "0");
      actions.getEventDetail(eventId);
    }
  };

  const betLockMarket = (MarketData, marketid) => {
    if (handleConfirmation()) {
      const updatedEventDetail = [...eventDetail];
      const marketIndex = updatedEventDetail.findIndex(
        (market) => market.MarketId === marketid
      );

      if (marketIndex !== -1) {
        const updatedMarket = {
          ...updatedEventDetail[marketIndex],
          Liquidity: MarketData?.Liquidity == 0 ? 1 : 0,
        };
        // Replace the market with the updated one
        updatedEventDetail[marketIndex] = updatedMarket;
      }

      // Now you can dispatch the updated eventDetail using Redux.
      store.dispatch(setEventDetail(updatedEventDetail));
      actions.betLockMarketAction(marketid);
    }
  };

  const userBookHandler = (MarketName, marketId) => {
    store.dispatch(setUserBookName(MarketName));
    let data = {
      marketId: marketId,
      uid: encodeURIComponent(
        JSON.parse(localStorage.getItem("loginUserDetails"))?.uid
      ),
    };
    actions.getUserBook(data);
  };

  const betBMEditHandler = (marketId) => {
    actions.GetCustomeMarket(marketId);
    navigate(`/bmedit/${marketId}`);
  };

  const setUpdateFancyStatusHandler = (SelectionId) => {
    // const bm = eventDetail?.find((obj) => obj.MarketType === "Bookmaker");
    if (marketID) {
      const MID = marketID.replace("B", "");
      const data = {
        marketId: MID,
        fancyId: SelectionId,
      };
      actions.updateFancyStauts(data);
    }
  };

  const GetBetfairData = (ides) => {
    console.log("betfair " + Date.now());
    clearTimeout(BetfairTimer.current);
    $.ajax({
      type: "GET",
      timeout: 3000,
      url: "https://betfair.shriexchange.com/api/Odds/betfair/" + ides,
      dataType: "json",
      success: function (result, status, xhr) {
        store.dispatch(saveBfRMarketOdddsOther(result));
      },
      error: function (xhr, status, error) {},
      complete: function () {
        if (isTimer == 1)
          BetfairTimer.current = setTimeout(() => {
            GetBetfairData(ides);
          }, 2000);
      },
    });
  };

  const GetBMFancyData = () => {
    console.log("BM fancy " + Date.now());
    clearTimeout(bmfancyTimer.current);
    var BMID = marketID.replace("B", "");
    $.ajax({
      type: "GET",
      timeout: 3000,
      url: "https://api.shriexchange.com/api/betfair/bmFancy/" + BMID,
      dataType: "json",
      success: function (result, status, xhr) {
        store.dispatch(saveBetfareRetsBMAndFancy(result));

        try {
          if (result.fancy.length !== fancyCount) {
            fancyCount = result.fancy.length;
            console.log("Call undeclare list");
          //  actions.getundeclareFancyList(marketID);
          }
        } catch (ex) {}
      },
      error: function (xhr, status, error) {},
      complete: function () {
        if (isTimer == 1)
          bmfancyTimer.current = setTimeout(() => {
            GetBMFancyData();
          }, 2000);
      },
    });
  };
  const GetTossData = () => {
    var BMID = marketID.replace("B", "");
    console.log("Toss " + Date.now());
    clearTimeout(TossTimer.current);
    var userData = JSON.parse(localStorage.getItem("loginUserDetails"));
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.Token}`,
    };
    $.ajax({
      type: "GET",
      timeout: 3000,
      headers: headers,
      url: `${GETBETFARETOSSAPI}?marketids=${marketID}T`,
      dataType: "json",
      success: function (result, status, xhr) {
        store.dispatch(saveBfRTossOddds(result));
      },
      error: function (xhr, status, error) {},
      complete: function () {
        if (isTimer == 1)
          TossTimer.current = setTimeout(() => {
            GetTossData();
          }, 2000);
      },
    });
  };
  const GetActiveFancyData = () => {
    var BMID = marketID.replace("B", "");
    console.log("Active Fancy " + Date.now());
    clearTimeout(ActiveFancyTimer.current);
    var userData = JSON.parse(localStorage.getItem("loginUserDetails"));
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.Token}`,
    };
    $.ajax({
      type: "GET",
      timeout: 3000,
      headers: headers,
      url: `${GETACTIVEFANCYAPI}?MarketId=${BMID}`,
      dataType: "json",
      success: function (result, status, xhr) {
        store.dispatch(setFancyActiveList(result.Result));
      },
      error: function (xhr, status, error) {},
      complete: function () {
        ActiveFancyTimer.current = setTimeout(() => {
          GetActiveFancyData();
        }, 2000);
      },
    });
  };
  const GetCustomBMData = () => {
    clearTimeout(CustomeBMTimer.current);
    console.log("Custom BM " + Date.now());
    var userData = JSON.parse(localStorage.getItem("loginUserDetails"));
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.Token}`,
    };
    $.ajax({
      type: "GET",
      timeout: 3000,
      headers: headers,
      url: `${GETCUSTOMEBMAPI}?MarketId=${marketID}`,
      dataType: "json",
      success: function (result, status, xhr) {
        store.dispatch(setCustomeBMList(result.Result));
      },
      error: function (xhr, status, error) {},
      complete: function () {
        if (isTimer == 1)
          CustomeBMTimer.current = setTimeout(() => {
            GetCustomBMData();
          }, 2000);
      },
    });
  };
  const GetMatchedBetList = () => {
    console.log("Bet List " + Date.now());
    clearTimeout(BetListTimer.current);
    var userData = JSON.parse(localStorage.getItem("loginUserDetails"));
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.Token}`,
    };
    $.ajax({
      type: "GET",
      timeout: 3000,
      headers: headers,
      url: `${GETUSERBETMODELAPI}?eventid=${eventId}`,
      dataType: "json",
      success: function (result, status, xhr) {      
        store.dispatch(setMatchBetData(result));
      },
      error: function (xhr, status, error) {},
      complete: function () {
        actions.getundeclareFancyList(marketID);
        if (isTimer == 1)
          BetListTimer.current = setTimeout(() => {
            GetMatchedBetList();

          }, 3000);
      },
    });
  };
  const GetFancyPostionList = () => {
    var BMID = marketID.replace("B", "");
    clearTimeout(fancyPostionTimer.current);
    console.log("fancy book List " + Date.now());
    var userData = JSON.parse(localStorage.getItem("loginUserDetails"));
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.Token}`,
    };
    $.ajax({
      type: "GET",
      timeout: 3000,
      headers: headers,
      url: `${GETFANCYPOSTIONAPI}?marketId=${BMID}`,
      dataType: "json",
      success: function (result, status, xhr) {
        store.dispatch(setFancyPostionData(result));
      },
      error: function (xhr, status, error) {},
      complete: function () {
        if (isTimer == 1)
          fancyPostionTimer.current = setTimeout(() => {
            GetFancyPostionList();
          }, 3000);
      },
    });
  };

  
   
  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar navTitle={"All Markets"} />
            <div className="container-fluid">
              <div className="d-flex flex-wrap" style={{ marginTop: "10px" }}>
                <div className="col-12 col-md-8 scrollable-content">
                  <div className="scrollable-content-inner">
                    {isMobile && <LiveTV />}{" "}
                    {/* Show LiveTV only in mobile view */}
                    <Bookmaker
                      eventDetail={eventDetail}
                      betLockMarket={betLockMarket}
                      betLockMarketStatus={betLockMarketStatus}
                      userBookHandler={userBookHandler}
                      betBMEditHandler={betBMEditHandler}
                      betEventStatusHandler={betEventStatusHandler}
                      EventId={eventId}
                      sportsId={sportId}
                    />
                    <FancyBets
                      fancyprofitLossData={fancyprofitLossData}
                      fancyOddsFareRate={filteredMainlist}
                      setUpdateFancyStatusHandler={setUpdateFancyStatusHandler}
                      marketID={marketID}
                      eventId={eventId}
                      EvBetStatus={fancySetting?.EvBetStatus}
                    />
                    <FancyUnDeclare
                      updatedFancyOddsFareRate={filterUndeclareList}
                      marketID={marketID}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="fixed-height-content">
                    {!isMobile && <LiveTV />}{" "}
                    {/* Show LiveTV only in desktop view */}
                    <AllBetList eventId={eventId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <LogoutModel />
      <BookModal />
      <FancyBetListModal />
      <FancyPleaceBet
        MarketId={marketID}
        EventId={eventId}
        sportsId={sportId}
      />
      {/* <MarketPleaceBet
        MarketId={marketID}
        EventId={eventId}
        sportsId={sportId}
      /> */}
      <FancyCurrentPostionModal />
    </>
  );
};

export default MyBookEvent;
