import React, { useState, useEffect } from "react";
import actions from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import CalendarModel from "../Models/CalendarModal";
import AddFancyModal from "../Models/AddFancyModal";
import { isSuperAdmin, isRoleExits } from "../../utils";
import { useSelector } from "react-redux";
import moment from "moment";
const MatchTable = ({ matchList, totalrow, handleAction }) => {
  let matchTypes = useSelector((state) => state.match?.matchList?.type);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [marketId, setMarketId] = useState("");
  const [eventId, setEventId] = useState("");
  const [type, setType] = useState(0);

  let [matchActionReq, setMatchActionReq] = useState({
    sportsId: 4,
    value: null,
    skipRec: 0,
    take: 10,
  });
  const [marketTimeUpdateData, setMarketTimeUpdateData] = useState({});

  useEffect(() => {
    setType(matchTypes);
    //console.log(matchTypes, "opppppp");
  }, [matchTypes]);

  useEffect(() => {
    // //debugger
    setData(matchList);
  }, [matchList]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalrow / 10));
  }, [totalrow]);

  const getMatchList = (sportId) => {
    let data = { ...matchActionReq, sportsId: sportId };
    actions.getOpenMatchList({ ...data, type });
    //if (type === "open") { actions.getOpenMatchList({ ...data, type: 0 }) }
    //if (type === "completed") { actions.getOpenMatchList({ ...data, type: 1 }) }
    //if (type === "suspended") { actions.getOpenMatchList({ ...data, type: 2 }) }
    // if (type === "completed") { actions.getCompletedMatchList(data) }
    // if (type === "suspended") { actions.getSuspendedMatchList(data) }
  };

  const handleBlockEventToggle = async (marketID, sportId) => {
    const updatedData = data.map((obj) => {
      if (obj.MarketId === marketID) {
        return { ...obj, MarketLiquidity: !obj.MarketLiquidity };
      }
      return obj;
    });

    setData(updatedData);
    // await actions.blockEventAction(marketID);
    await actions.betLockMarketAction(marketID);
    getMatchList(sportId);
  };

  const handleEventControl = async (eventID, status, sportId, marketTIme) => {
    //debugger;
    const updatedData = data.map((obj) => {
      if (obj.EventId === eventID) {
        return { ...obj, InPlay: status };
      }
      return obj;
    });

    setData(updatedData);
   // await actions.matchControlAction(eventID, status, type, marketTIme);
    await actions.eventControlAction(eventID, status, type, marketTIme);
    getMatchList(sportId);
  };

  const handleIsFancyActive = async (eventID, status, sportId, marketTIme) => {
    await actions.eventControlAction(eventID, status ? 0 : 1, 3, marketTIme);
   // await actions.eventControlAction(eventID, status, type, marketTIme);
    getMatchList(sportId);
  };

  const handleUpdateMarketTime = async (marketTIme) => {
    await actions.eventControlAction(
      marketTimeUpdateData?.EventId,
      marketTimeUpdateData?.InPlay,
      1,
      marketTIme
    );
    getMatchList(marketTimeUpdateData?.SportsId);
  };

  const handlePageNoChange = (newPageNo) => {
    setMatchActionReq((prevState) => ({ ...prevState, skipRec: newPageNo }));
    let data = {
      ...matchActionReq,
      sportsId: matchList[0]?.SportsId,
      skipRec: newPageNo,
    };
    actions.getOpenMatchList({ ...data, type });
    //if (type === "open") { actions.getOpenMatchList({ ...data, type: 0 }) }
    //if (type === "completed") { actions.getOpenMatchList({ ...data, type: 1 }) }
    //if (type === "suspended") { actions.getOpenMatchList({ ...data, type: 2 }) }
    // if (type === "open") { actions.getOpenMatchList(data) }
    // if (type === "completed") { actions.getCompletedMatchList(data) }
    // if (type === "suspended") { actions.getSuspendedMatchList(data) }
  };

  const DynamicPagination = (number) => {
    const maxPagesToShow = 4; // Maximum number of pages to show
    const startPage = Math.max(
      1,
      Math.min(number - maxPagesToShow + 1, matchActionReq?.skipRec + 1)
    ); // Determine the start page
    const divs = [];

    for (
      let i = startPage;
      i < startPage + maxPagesToShow && i <= number;
      i++
    ) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${
              matchActionReq?.skipRec + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setMatchActionReq((prevState) => ({
                ...prevState,
                skipRec: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <div>
      <AddFancyModal MarketId={marketId} EventId={eventId} />
      <CalendarModel handleUpdateMarketTime={handleUpdateMarketTime} />
      <table
        className="table table-bordered dataTable"
        id="dataTable"
        width="100%"
        cellSpacing={0}
        role="grid"
        aria-describedby="dataTable_info"
        style={{ width: "100%" }}
      >
        <thead>
          <tr role="row">
            {/* <th>Set Fav</th> */}

            <th>EventId</th>
            <th>Event Name</th>
            <th>Event Time</th>

            {type !== 2 ? (
              <>
                {/* {isSuperAdmin() && <th>Block Event</th>} */}
                {isSuperAdmin() && <th>Event Control</th>}
              </>
            ) : (
              <th>Winner</th>
            )}

            {(isSuperAdmin() || isRoleExits("fancy_result")) && <th>Fancy </th>}

            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((match, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "even hover-row" : "odd hover-row"}
              >
                <td>{match?.EventId}</td>

                <td onClick={() => handleAction(match?.EventId)}>
                  {match?.MatchName}
                  <br></br> <small>{match?.SeriesName}</small>
                </td>
                <td>{moment(match?.MarketTime).format("DD-MM-YYYY h:mm A")}</td>

                {type !== 2 ? (
                  <>
                    {/* {isSuperAdmin() && (
                      <td>
                        <label className="toggle">
                          <input
                            type="checkbox"
                            checked={match?.MarketLiquidity}
                            onChange={() =>
                              handleBlockEventToggle(
                                match?.MarketId,
                                match?.SportsId
                              )
                            }
                          />
                          <div className="slider"></div>
                        </label>
                      </td>
                    )} */}
                    {isSuperAdmin() && (
                      <td>
                        <select
                          className="form-control custom-select-box"
                          value={match?.InPlay}
                          onChange={(e) =>
                            handleEventControl(
                              match?.EventId,
                              e.target.value,
                              match?.SportsId,
                              match?.MarketTime
                            )
                          }
                        >
                          <option value="0">Open</option>
                          <option value="1">Inplay</option>

                          <option value="2">Suspended</option>
                        </select>
                      </td>
                    )}
                  </>
                ) : (
                  <td>{match?.WinnerName}</td>
                )}

                {(isSuperAdmin() || isRoleExits("fancy_result")) && (
                  <td>
                    {type === 0 ? (
                      <>
                        {isSuperAdmin() && (
                          <button
                            className="custom-grey-btn"
                            onClick={() =>
                              navigate(`/fancysetting/${match?.MarketId}`)
                            }
                          >
                            Fancy List
                          </button>
                        )}
                        {isSuperAdmin() && (
                          <button
                            className={
                              match?.IsFancyActive
                                ? "custom-green-btn"
                                : "custom-red-btn"
                            }
                            onClick={() =>
                              handleIsFancyActive(
                                match?.EventId,
                                match?.IsFancyActive,
                                match?.SportsId,
                                match?.MarketTime
                              )
                            }
                          >
                            IsFancyActive
                          </button>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {isRoleExits("fancy_result") && (
                      <button
                        className="custom-yellow-btn"
                        onClick={() =>
                          navigate(`/fancyresult/${match?.MarketId}`)
                        }
                      >
                        Fancy CT
                      </button>
                    )}
                  </td>
                )}

                <td>
                  <ul className="d-flex list-unstyled">
                    {/* <li><GrIcons.GrView className='mr-2' /></li> */}
                    {isSuperAdmin() && (
                      <li
                        data-toggle="modal"
                        data-target="#calendarModal"
                        onClick={() => setMarketTimeUpdateData(match)}
                      >
                        <FaIcons.FaCalendar className="mr-1" />
                      </li>
                    )}
                    <li>
                      {type === 0 ? (
                        <>
                          {" "}
                          {(isRoleExits("fancymanual") ||
                            isRoleExits("addfancy") ||
                            isRoleExits("addfancy") ||
                            isRoleExits("create_fancy") ||
                            isSuperAdmin()) && (
                            <button
                              className="custom-grey-btn"
                              data-toggle="modal"
                              data-target="#addFancyModal"
                              onClick={() => {
                                setMarketId(match?.MarketId);
                                setEventId(match?.EventId);
                              }}
                            >
                              Add Fancy
                            </button>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <button
                        className="custom-grey-btn"
                        onClick={() =>
                          navigate(
                            `/eventbets/${match?.EventId}/${match?.MarketId}`
                          )
                        }
                      >
                        Bet List
                      </button>
                      <button
                        className="custom-grey-btn"
                        onClick={() =>
                          navigate(
                            `/my-book-event/${match?.MarketId}/${match?.EventId}/${match?.SportsId}`,
                            {
                              state: {
                                seriesName: match?.SeriesName,
                                matchName: match?.MarketName,
                              },
                            }
                          )
                        }
                      >
                        MB
                      </button>
                    </li>
                  </ul>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data && data?.length > 0 && (
        <div className="row m-1">
          <div className="col-sm-12 col-md-5">
            <div
              className="dataTables_info"
              id="dataTable_info"
              role="status"
              aria-live="polite"
            >
              Total Page : {totalPage}
            </div>
          </div>
          <div className="col-sm-12 col-md-7">
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="dataTable_paginate"
            >
              <ul className="pagination d-flex justify-content-end">
                <li
                  className="paginate_button page-item previous disabled"
                  id="dataTable_previous"
                >
                  <button
                    className="pagination-action-btn"
                    disabled={matchActionReq?.skipRec === 0 ? true : false}
                    onClick={() => {
                      handlePageNoChange(matchActionReq?.skipRec - 1);
                    }}
                  >
                    Previous
                  </button>
                </li>
                {DynamicPagination(totalPage)}
                <li
                  className="paginate_button page-item next"
                  id="dataTable_next" >
                  <button
                    className="pagination-action-btn"
                    disabled={
                      matchActionReq?.skipRec === totalPage - 1 ? true : false
                    }
                    onClick={() => {
                      handlePageNoChange(matchActionReq?.skipRec + 1);
                    }}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchTable;
