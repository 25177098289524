import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const TableItemComponent = ({ data }) => {
  const marketAnalysisData = useSelector(
    (state) => state.match?.MarketAnalysis?.Result
  );

  const navigate = useNavigate();

  const handleRowClick = (match) => {
    // console.log("Row clicked:", match);
    navigate(
      `/my-book-event/${match?.MarketId}/${match?.EventId}/${match?.SportsId}`,
      { state: { matchName: match?.MarketName } }
    );
  };

  return (
    <div>
      <table
        className="table table-bordered dataTable"
        id="dataTable"
        width="100%"
        cellSpacing={0}
        role="grid"
        aria-describedby="dataTable_info"
        style={{ width: "100%" }}
      >
        <thead>
          <tr role="row">
            <th className="text-center" style={{ width: "80.2px" }}>
              EventId
            </th>
            <th className="text-center" style={{ width: "140.2px" }}>
              Match Name
            </th>
            <th className="text-center" style={{ width: "180.2px" }}>
              Profit Loss (R1 / R2 / R3)
            </th>
            <th className="text-center" style={{ width: "50.2px" }}>
              Bet Count
            </th>
            <th className="text-center" style={{ width: "120.2px" }}>
              MarketTime
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(marketAnalysisData) &&
            marketAnalysisData?.map((match, index) => {
              let R1 = 0,
                R2 = 0,
                R3 = 0;
              var PL = match.profitLoss.split(" / ");
              if (PL[0]) R1 = PL[0];
              if (PL[1]) R2 = PL[1];
              if (PL[2]) R3 = PL[2];
              return (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "even" : "odd"}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(match)}
                >
                  <td>{match.EventId}</td>
                  <td>{match.MarketName}</td>
                  <td className="text-center">
                    <span
                      style={{
                        color: R1 < "0" ? "red" : "green",
                      }}
                    >
                      {R1}
                    </span>{" "}
                    /{" "}
                    <span
                      style={{
                        color: R2 < "0" ? "red" : "green",
                      }}
                    >
                      {R2}
                    </span>{" "}
                    /{" "}
                    <span
                      style={{
                        color: R3 < "0" ? "red" : "green",
                      }}
                    >
                      {R3}
                    </span>
                  </td>
                  <td className="text-center">
                    {match.betcount}
                    {/* <div className="text-danger">{match.score1}</div> */}
                  </td>
                  <td className="text-center">
                    {match.MarketTime}
                    {/* <div className="text-danger">{match.score2}</div> */}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TableItemComponent;
