import React, { useEffect, useState } from "react";
//import RoleDropdown from '../RoleDropdown';
import actions from "../../redux/actions";
import { useParams } from "react-router-dom";
import { notifySuccess, notifyError } from "../ToastContext";

const EditClientModal = ({
  editFormData,
  handleClientList,
  showModal,
  handleModalClose,
}) => {
  let { mid } = useParams();

  const [formData, setFormData] = useState({
    Name: "",
    UserName: "",
    Password: "",
    role: "Admin",
    ExposureLimit: 0,
    MCO: "0",
    SCO: "0",
    BCO: "0",
    MyShare: 0,
    uid: "",
    ReferById: 0,
    mid: "",
  });
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    //setFormData((prevState) => ({ ...prevState, role: editFormData?.Role, Name: editFormData?.Name, UserName: editFormData?.UserName, Share: editFormData?.MyShare, mid: editFormData?.uid, uid: editFormData?.uid }))
    setFormData({
      ...editFormData,
      SCO: String(editFormData?.SCO),
      BCO: String(editFormData?.BCO),
      MCO: String(editFormData?.MCO),
    });
  }, [editFormData]);

  useEffect(() => {
    setOpenModal(showModal);
    //console.log(showModal, "useeffect show madal");
  }, [showModal]);

  //const [selectedRole, setSelectedRole] = useState('');
  //const loggedInUserRole = JSON.parse(localStorage.getItem("loginUserDetails")).Role;
  // const handleRoleChange = (selectedValue) => {
  //     setSelectedRole(selectedValue);
  //     setFormData({ ...formData, role: selectedValue });
  // };
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: undefined }));
  };

  const validateForm = () => {
    const validationErrors = {};

    // if (!formData.Name) {
    //     validationErrors.Name = 'Name is required';
    // }
    // if (formData.Name && !/^[a-zA-Z ]*$/.test(formData.Name)) {
    //     validationErrors.Name = 'Name is not valid';
    // }

    // if (!formData.UserName) {
    //     validationErrors.UserName = 'User Name is required';
    // }
    // if (formData.UserName && !/^[a-zA-Z0-9_-]*$/.test(formData.UserName)) {
    //     validationErrors.UserName = 'User Name is not valid';
    // }

    // if (!formData.role) {
    //     validationErrors.role = 'User Role is required';
    // }

    // if (!formData.Password) {
    //     validationErrors.Password = 'Password is required';
    // }
    if (!formData.ExposureLimit && formData.role === "Client") {
      validationErrors.ExposureLimit = "Exposure is required";
    }

    if (
      (formData.MyShare === undefined || formData.MyShare < 0) &&
      formData.role !== "Client"
    ) {
      validationErrors.MyShare = "Share is required";
    } else if (formData.MyShare < 0 && formData.role !== "Client") {
      validationErrors.MyShare = "Share must be a positive";
    }

    if (!formData.BCO) {
      validationErrors.BCO = "Book Maker Commission is required";
    }

    if (!formData.BCO.match(/^(\d+(\.\d{0,2})?|\.\d{1,2})$/)) {
      validationErrors.BCO = "Invalid Book Maker Commission";
    }

    if (!formData.MCO) {
      validationErrors.MCO = "Match Commission is required";
    }

    if (!formData.MCO.match(/^(\d+(\.\d{0,2})?|\.\d{1,2})$/)) {
      validationErrors.MCO = "Invalid  Match Commission";
    }

    if (!formData.SCO) {
      validationErrors.SCO = "Fancy Commission is required";
    }
    if (!formData.SCO.match(/^(\d+(\.\d{0,2})?|\.\d{1,2})$/)) {
      validationErrors.SCO = "Invalid Fancy Commission";
    }
    if (formData.role < 0) {
      validationErrors.role = "User Role should be a positive number";
    }

    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        let data = {
          uid: formData?.uid,
          ReferById: formData?.ReferById,
          mid: decodeURIComponent(mid),
          MCO: parseFloat(formData?.MCO),
          SCO: parseFloat(formData?.SCO),
          BCO: parseFloat(formData?.BCO),
          Share: parseInt(formData?.MyShare),
          ExposureLimit: parseFloat(formData?.ExposureLimit),
          Remark: formData?.Remark,
        };

        const res = await actions.updateSignUp(data);
        handleModalClose();
        if (res?.data?.Status) {
          notifySuccess(res?.data?.Result);
          handleClientList();
        } else {
          notifyError(res?.data?.Result);
        }
      } catch (error) {
        //console.log("Error:", error);
      }
    }
  };
  return (
    <div>
      {openModal && (
        <div
          className="modal"
          id="editClientModal"
          tabIndex={-1}
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="exampleModalLabel">
                  Update Client
                </h6>
                <button
                  className="close"
                  type="button"
                  aria-label="Close"
                  onClick={() => {
                    handleModalClose();
                    setErrors({});
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group row">
                  <div className=" col-sm-6 mb-3 mb-sm-0">
                    <label>Name</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.Name ? "is-invalid" : ""
                      }`}
                      id="Name"
                      name="Name"
                      placeholder="Name"
                      value={formData.Name}
                      onChange={handleChange}
                      readOnly
                    />
                    {errors.Name && (
                      <div className="invalid-feedback">{errors.Name}</div>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <label>User Name</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.UserName ? "is-invalid" : ""
                      }`}
                      id="UserName"
                      name="UserName"
                      placeholder="User Name"
                      value={formData.UserName}
                      onChange={handleChange}
                      readOnly
                    />
                    {errors.UserName && (
                      <div className="invalid-feedback">{errors.UserName}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Role</label>
                    <select
                      id="userRole"
                      class="form-control"
                      name="role"
                      value={formData?.role}
                      onChange={handleChange}
                      readOnly
                    >
                      <option value="Admin">Admin</option>
                      <option value="SubAdmin">SubAdmin</option>
                      <option value="Master">Master</option>
                      <option value="Agent">Agent</option>
                      <option value="Client">Client</option>
                      <option value="Manager">Manager</option>
                    </select>
                    {/* //<RoleDropdown loggedInUserRole={formData.role} onRoleChange={handleRoleChange} readOnlyRole={true} /> */}
                    {errors.role && (
                      <div className="invalid-feedback">{errors.role}</div>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <label>BookMaker Commission</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.BCO ? "is-invalid" : ""
                      }`}
                      id="BCO"
                      placeholder="BookMaker Commission"
                      value={formData.BCO}
                      onChange={handleChange}
                    />
                    {errors.BCO && (
                      <div className="invalid-feedback">{errors.BCO}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Password</label>

                    <input
                      type="Password"
                      className={`form-control  ${
                        errors.Password ? "is-invalid" : ""
                      }`}
                      id="Password"
                      name="Password"
                      placeholder="Password"
                      value={formData.Password}
                      onChange={handleChange}
                      readOnly
                    />
                    {errors.Password && (
                      <div className="invalid-feedback">{errors.Password}</div>
                    )}
                  </div>

                  <div className="col-sm-6 mb-3 mb-sm-0">
                    {formData?.role !== "Client" ? (
                      <>
                        <label>Share</label>
                        <input
                          type="number"
                          className={`form-control  ${
                            errors.MyShare ? "is-invalid" : ""
                          }`}
                          id="MyShare"
                          name="MyShare"
                          placeholder="MyShare"
                          value={formData.MyShare}
                          onChange={handleChange}
                        />

                        {errors.MyShare && (
                          <div className="invalid-feedback">
                            {errors.MyShare}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <label>Exposure</label>
                        <input
                          type="number"
                          className={`form-control  ${
                            errors.ExposureLimit ? "is-invalid" : ""
                          }`}
                          id="ExposureLimit"
                          placeholder="Exposure"
                          value={formData.ExposureLimit}
                          onChange={handleChange}
                        />
                        {errors.ExposureLimit && (
                          <div className="invalid-feedback">
                            {errors.ExposureLimit}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Commission</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.MCO ? "is-invalid" : ""
                      }`}
                      id="MCO"
                      placeholder="Match Commission"
                      value={formData.MCO}
                      onChange={handleChange}
                      readOnly
                    />
                    {errors.MCO && (
                      <div className="invalid-feedback">{errors.MCO}</div>
                    )}
                  </div>
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Fancy Commission</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.SCO ? "is-invalid" : ""
                      }`}
                      id="SCO"
                      placeholder="Fancy Commission"
                      value={formData.SCO}
                      onChange={handleChange}
                    />
                    {errors.SCO && (
                      <div className="invalid-feedback">{errors.SCO}</div>
                    )}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Remark</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.Remark ? "is-invalid" : ""
                      }`}
                      id="MCO"
                      placeholder="Remark"
                      value={formData.Remark}
                      onChange={(e) =>
                        setFormData({ ...formData, Remark: e.target.value })
                      }
                    />
                    {errors.Remark && (
                      <div className="invalid-feedback">{errors.Remark}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="modal-submit-btn"
                  type="button"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="modal-cancel-btn"
                  type="button"
                  onClick={() => {
                    handleModalClose();
                    setErrors({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {openModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default EditClientModal;
