import React, { useEffect } from "react";

// Declare google as a global variable
/* global google */

const loadGoogleMapsScript = (callback) => {
  const existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA-TOwoPElfv8LYtopV4aesV65AZSgBU00`;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) {
    callback();
  }
};

const OsmMap = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const latlongString = urlParams.get("latlong");
  let latitude = 0,
    longitude = 0;

  if (latlongString && /^[0-9.-]+\s*,\s*[0-9.-]+$/.test(latlongString)) {
    [latitude, longitude] = latlongString.split(",").map(parseFloat);
  } else {
    console.error("Invalid latlong string:", latlongString);
  }

  useEffect(() => {
    if (isNaN(latitude) || isNaN(longitude)) {
      return;
    }

    loadGoogleMapsScript(() => {
      const map = new google.maps.Map(document.getElementById("google-map"), {
        center: { lat: latitude, lng: longitude },
        zoom: 17,
      });

      const customIcon = {
        url: "/img/location_icon.png",
        anchor: new google.maps.Point(26, 48),
      };

      const marker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
        icon: customIcon,
      });

      console.log("Marker:", marker);
    });
  }, [latitude, longitude]);

  return <div id="google-map" style={{ height: "300px" }} />;
};

export default OsmMap;
