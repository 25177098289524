import axios from "axios";
import {
  GETUSERSEARCHLIST,
  GETUSERLIST,
  LOGINAPISUPER,
  LOGINAPI,
  POSTSIGNUPMODEL,
  UPDATESIGNUPMODEL,
  GETBALANCEDETAILS,
  CHANGEPWD,
} from "./url";

class AuthServiceClass {
  async loginService(data) {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axios.post(`${LOGINAPISUPER}`, data, {
      headers: headers,
    });
  }

  async getUserListService(userName) {
    let data = JSON.parse(localStorage.getItem("loginUserDetails"));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data?.Token}`,
    };

    return await axios.get(`${GETUSERLIST}?value=${userName}`, {
      headers: headers,
    });
  }

  async getUserSearchListService(userName) {
    let data = JSON.parse(localStorage.getItem("loginUserDetails"));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data?.Token}`,
    };

    return await axios.get(`${GETUSERSEARCHLIST}?value=${userName}`, {
      headers: headers,
    });
  }

  async postSignUpService(data) {
    let jsonData = JSON.parse(localStorage.getItem("loginUserDetails"));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jsonData?.Token}`,
    };

    return await axios.post(`${POSTSIGNUPMODEL}`, data, {
      headers: headers,
    });
  }
  async updateSignUpService(data) {
    let jsonData = JSON.parse(localStorage.getItem("loginUserDetails"));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jsonData?.Token}`,
    };

    return await axios.post(`${UPDATESIGNUPMODEL}`, data, {
      headers: headers,
    });
  }

  async getBalanceDetailsService() {
    let jsonData = JSON.parse(localStorage.getItem("loginUserDetails"));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jsonData?.Token}`,
    };

    return await axios.get(`${GETBALANCEDETAILS}?userId=1`, {
      headers: headers,
    });
  }

  async changePwdService(data) {
    let jsonData = JSON.parse(localStorage.getItem("loginUserDetails"));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jsonData?.Token}`,
    };

    return await axios.get(
      `${CHANGEPWD}?newPwd=${data?.newPwd}&oldPwd=${data?.oldPwd}`,
      {
        headers: headers,
      }
    );
  }
}

const AuthService = new AuthServiceClass();
export default AuthService;