import React, { useState } from 'react';
import RoleDropdown from '../../../components/RoleDropdown';
import actions from '../../../redux/actions';
const AddClientForm = () => {
    const [formData, setFormData] = useState({
        Name: "",
        UserName: "",
        Password: "",
        ReferById: 0,
        mid: "",   //uid send 
        role: "Admin",
        ExposureLimit: 0,
        MCO: 2,
        SCO: 0,
        BCO: 0,
        Share: 0,
        DomainColour: "",
        Remark:""
    });

    const [selectedRole, setSelectedRole] = useState('');
    const loggedInUserRole = 'SuperAdmin';
    const handleRoleChange = (selectedValue) => {
        setSelectedRole(selectedValue);
        setFormData({ ...formData, role: selectedValue });
    };
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [id]: undefined }));

    };

    const validateForm = () => {
        const validationErrors = {};

        if (!formData.Name) {
            validationErrors.Name = 'Name is required';
        }

        if (!formData.UserName) {
            validationErrors.UserName = 'User Name is required';
        }

        if (!formData.role) {
            validationErrors.role = 'User Role is required';
        }

        if (!formData.Password) {
            validationErrors.Password = 'Password is required';
        }
        if (!formData.ExposureLimit && selectedRole !== 'Client') {
            validationErrors.ExposureLimit = 'Exposure is required';
        }

        if (!formData.Share && selectedRole !== 'Client') {
            validationErrors.share = 'Share is required';
        }

        if (!formData.BCO) {
            validationErrors.BCO = 'Book Maker Commission is required';
        }

        if (!formData.MCO) {
            validationErrors.MCO = 'Match Commission is required';
        }

        if (!formData.SCO) {
            validationErrors.SCO = 'Fancy Commission is required';
        }

        if (formData.role < 0) {
            validationErrors.role = 'User Role should be a positive number';
        }

        return validationErrors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            //add api when provide
            let data = { ...formData, mid: JSON.parse(localStorage.getItem("loginUserDetails"))?.uid };
            console.log(JSON.parse(localStorage.getItem("loginUserDetails"))?.uid, data, 'ooooooooooooooo')
            // try {
            //     const res = await actions.postSignUp(data)
            //     if (res?.data?.Status) {
            //         alert(res?.data?.Status);
            //     } else {
            //         alert(res?.data?.Status);
            //     }
            // } catch (error) {
            //     console.log('Error:', error);
            // }
        }
    };


    return (
        <div className="container-fluid">
            {/* Page Heading */}
            <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>
            {/* Form Content */}
            <div className="card shadow mb-4 col-lg-8">
                <div className="card-body">
                    <form className="user" onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <div className=" col-sm-6 mb-3 mb-sm-0">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className={`form-control  ${errors.Name ? 'is-invalid' : ''}`}
                                    id="Name"
                                    name='Name'
                                    placeholder="Name"
                                    value={formData.Name}
                                    onChange={handleChange}
                                />
                                {errors.Name && <div className="invalid-feedback">{errors.Name}</div>}
                            </div>
                            <div className="col-sm-6">
                                <label>User Name</label>
                                <input
                                    type="text"
                                    className={`form-control  ${errors.UserName ? 'is-invalid' : ''}`}
                                    id="UserName"
                                    name='UserName'
                                    placeholder="User Name"
                                    value={formData.UserName}
                                    onChange={handleChange}
                                />
                                {errors.UserName && <div className="invalid-feedback">{errors.UserName}</div>}

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <RoleDropdown loggedInUserRole={loggedInUserRole} onRoleChange={handleRoleChange} />
                                {errors.role && <div className="invalid-feedback">{errors.role}</div>}

                            </div>
                            <div className="col-sm-6">
                                <label>BookMaker Commission</label>
                                <input
                                    type="number"
                                    className={`form-control  ${errors.BCO ? 'is-invalid' : ''}`}
                                    id="BCO"
                                    placeholder="BookMaker Commission"
                                    value={formData.BCO}
                                    onChange={(e) => setFormData({ ...formData, BCO: parseInt(e.target.value) })}
                                />
                                {errors.BCO && <div className="invalid-feedback">{errors.BCO}</div>}

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <label>Password</label>

                                <input
                                    type="Password"
                                    className={`form-control  ${errors.Password ? 'is-invalid' : ''}`}
                                    id="Password"
                                    name='Password'
                                    placeholder="Password"
                                    value={formData.Password}
                                    onChange={handleChange}
                                />
                                {errors.Password && <div className="invalid-feedback">{errors.Password}</div>}

                            </div>

                            <div className="col-sm-6 mb-3 mb-sm-0">
                                {selectedRole !== 'Client' ?
                                    (
                                        <>
                                            <label>Share</label>
                                            <input
                                                type="text"
                                                className={`form-control  ${errors.share ? 'is-invalid' : ''}`}
                                                id="share"
                                                placeholder="Share"
                                                value={formData.Share}
                                                onChange={(e) => setFormData({ ...formData, Share: parseInt(e.target.value) })}
                                            />

                                            {errors.share && <div className="invalid-feedback">{errors.share}</div>}
                                        </>
                                    ) :
                                    (
                                        <>
                                            <label>Exposure</label>
                                            <input
                                                type="text"
                                                className={`form-control  ${errors.ExposureLimit ? 'is-invalid' : ''}`}
                                                id="ExposureLimit"
                                                placeholder="Exposure"
                                                value={formData.ExposureLimit}
                                                onChange={(e) => setFormData({ ...formData, ExposureLimit: parseInt(e.target.value) })}
                                            />
                                            {errors.ExposureLimit && <div className="invalid-feedback">{errors.ExposureLimit}</div>}
                                        </>
                                    )}

                            </div>

                        </div>

                        <div className="form-group row mb-3">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <label>Commission</label>
                                <input
                                    type="number"
                                    readOnly
                                    className={`form-control  ${errors.MCO ? 'is-invalid' : ''}`}
                                    id="MCO"
                                    placeholder="Match Commission"
                                    value={formData.MCO}
                                    onChange={(e) => setFormData({ ...formData, MCO: parseInt(e.target.value) })}
                                />
                                {errors.MCO && <div className="invalid-feedback">{errors.MCO}</div>}

                            </div>
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <label>Fancy Commission</label>
                                <input
                                    type="number"
                                    className={`form-control  ${errors.SCO ? 'is-invalid' : ''}`}
                                    id="SCO"
                                    placeholder="Fancy Commission"
                                    value={formData.SCO}
                                    onChange={(e) => setFormData({ ...formData, SCO: parseInt(e.target.value) })}
                                />
                                {errors.SCO && <div className="invalid-feedback">{errors.SCO}</div>}

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className=" col-sm-6 mb-3 mb-sm-0">
                                <label>Remark</label>
                                <input
                                    type="text"
                                    className={`form-control  ${errors.Remark ? 'is-invalid' : ''}`}
                                    id="Remark"
                                    name='Remark'
                                    placeholder="Remark"
                                    value={formData.Remark}
                                    onChange={handleChange}
                                />
                                {errors.Remark && <div className="invalid-feedback">{errors.Remark}</div>}
                            </div>
                            
                        </div>
                        <button type="submit" className="btn btn-primary btn-block my-4">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddClientForm;
