import React, { useState, useEffect } from "react";
import actions from "../../redux/actions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import moment from "moment";
import * as RiIcons from "react-icons/ri";

const EventBets = () => {
  const MarketName = useSelector((state) => state.match?.MarketName?.Result);
  const MarketCurrentBets = useSelector((state) => state.match?.MarketCurrentBets?.Result);
  const totalrow = useSelector((state) => state.match?.MarketCurrentBets?.Count);

  let totalPage = Math.ceil(totalrow / 10);
  let { eventid } = useParams();
  const [data, setData] = useState({
    userName: "None",
    Eventid: "",
    marketType: "MATCH_ODDS",
    betStaus: "Pending",
    fancyid: 0,
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD HH:mm"),
    endDate: moment().format("YYYY-MM-DD HH:mm"),
    skipRec: 0,
    pagesize: 10,
  });

  useEffect(() => {
    if (eventid) {
      let data = {
        userName: "None",
        Eventid: eventid,
        marketType: "MATCH_ODDS",
        betStaus: "Pending",
        fancyid: 0,
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD HH:mm"),
        endDate: moment().format("YYYY-MM-DD HH:mm"),
        skipRec: 0,
        pagesize: 10,
      };
      actions.getMarketNameAction(eventid);
      actions.getMarketCurrentBetsAction(data);
    }
  }, [eventid]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const loadMarketCurrectBets = (e) => {
    e.preventDefault();
    actions.getMarketCurrentBetsAction({ ...data, Eventid: eventid });
  };

  const handlePageNoChange = (newPageNo) => {
    setData((prevState) => ({ ...prevState, skipRec: newPageNo }));
    actions.getMarketCurrentBetsAction({
      ...data,
      skipRec: newPageNo,
      Eventid: eventid,
    });
  };
  const DynamicPagination = (number) => {
    const maxPagesToShow = 4; // Maximum number of pages to show
    const startPage = Math.max(
      1,
      Math.min(number - maxPagesToShow + 1, data?.skipRec + 1)
    ); // Determine the start page

    const divs = [];

    for (
      let i = startPage;
      i < startPage + maxPagesToShow && i <= number;
      i++
    ) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${data?.skipRec + 1 === i
              ? "pagination-number "
              : "pagination-inactive-number "
              }`}
            onClick={() => {
              setData((prevState) => ({
                ...prevState,
                skipRec: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <NavbarSidebarWrapper>
      <div className="content-header">Event Bets</div>
      <div className="form-container">
        <form>
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="form-group">
                <label htmlFor="textbox1">Market Type</label>
                <select className="form-control" value={data?.marketType} name="marketType" onChange={handleChange}>
                  <option value="0">All</option>
                  {MarketName &&
                    MarketName?.map((item, index) => (
                      <option value={item?.MarketType} key={index}>
                        {item?.MarketType}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="form-group">
                <label htmlFor="textbox2">Bet Status</label>
                <select className="form-control" value={data?.betStaus} name="betStaus" onChange={handleChange}>
                  <option value="Pending">Active</option>
                  <option value="Settled">Settled</option>
                </select>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="form-group">
                <label htmlFor="textbox2">User Name</label>
                <input type="text" className="form-control" value={data?.userName} name="userName" onChange={handleChange} />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="form-group">
                <label htmlFor="textbox2"> Choose from Date</label>
                <input type="datetime-local" className="form-control" value={moment(data?.startDate)?.format("YYYY-MM-DDTHH:mm")} name="startDate" onChange={(e) => setData({ ...data, startDate: moment(e.target.value).format("YYYY-MM-DD HH:mm"), })} />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="form-group">
                <label htmlFor="textbox2">Choose to Date</label>
                <input type="datetime-local" className="form-control" value={moment(data?.endDate)?.format("YYYY-MM-DDTHH:mm")} name="endDate" onChange={(e) => setData({ ...data, endDate: moment(e.target.value).format("YYYY-MM-DD HH:mm"), })} />
              </div>
            </div>
            <div className="col-6 col-md-3 load-btn-container mt-2">
              <button className="btn btn-secondary" onClick={loadMarketCurrectBets}>
                Load
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="card shadow mb-4">
        {MarketCurrentBets &&
          Array.isArray(MarketCurrentBets) &&
          MarketCurrentBets?.length > 0 && (
            <div className="card-title m-2">
              <button className="custom-yellow-btn" style={{ color: "white", fontSize: "14px" }}>
                Delete Selected Bets<RiIcons.RiDeleteBinLine />
              </button>
              <button className="custom-yellow-btn" style={{ color: "white", fontSize: "14px" }}>
                Delete All Bets<RiIcons.RiDeleteBinLine />
              </button>
            </div>
          )}

        <div className="table-responsive">
          <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
            <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
              <thead>
                <tr role="row">
                  <th>
                    <input type="checkbox" className="form-control" />
                  </th>
                  <th className="text-no-wrap">User Name</th>
                  <th className="text-no-wrap">Match Name</th>
                  <th className="text-no-wrap">Market Name</th>
                  <th className="text-no-wrap">Selection Name</th>
                  <th className="text-no-wrap">Odds</th>
                  <th className="text-no-wrap">Stack</th>
                  <th className="text-no-wrap">Bet Type</th>
                  <th className="text-no-wrap">Ip Address</th>
                  <th className="text-no-wrap">Date</th>
                  <th className="text-no-wrap">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {MarketCurrentBets &&
                  Array.isArray(MarketCurrentBets) &&
                  MarketCurrentBets.map((event, index) => (
                    <tr key={index} className={index % 2 === 0 ? "even hover-row" : "odd hover-row"}>
                      <td>
                        <input type="checkbox" className="form-control" />
                      </td>
                      <td className="text-no-wrap">{event?.UserName}</td>
                      <td className="text-no-wrap">{event?.MatchName}</td>
                      <td className="text-no-wrap">{event?.MarketType}</td>
                      <td className="text-no-wrap">{event?.SelectionName}</td>
                      <td className="text-no-wrap">{event?.Odds}</td>
                      <td className="text-no-wrap">{event?.Stack}</td>
                      <td className="text-no-wrap">{event?.BetType}</td>
                      <td className="text-no-wrap">{event?.IP_Address}</td>
                      <td className="text-no-wrap">{event?.createdOn}</td>
                      <td className="text-no-wrap">{event?.BetStatus}</td>
                      <td>
                        <RiIcons.RiDeleteBinLine style={{ fontSize: "15px" }} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {MarketCurrentBets && MarketCurrentBets.length > 0 && (
              <div className="row m-1">
                <div className="col-sm-12 col-md-5">
                  <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">
                    Total Page : {totalPage}
                  </div>
                </div>
                <div className="col-sm-12 col-md-7">
                  <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                    <ul className="pagination d-flex justify-content-end">
                      <li className="paginate_button page-item previous disabled" id="dataTable_previous">
                        <button className="pagination-action-btn" disabled={data?.skipRec === 0 ? true : false} onClick={() => { handlePageNoChange(data?.skipRec - 1); }}>
                          Previous
                        </button>
                      </li>
                      {DynamicPagination(totalPage)}
                      {/* {totalPage === 1 ? '' : <span style={{ color: "white" }}>...</span>} */}
                      {/* {totalPage === 1 ? '' :
                                <li className="paginate_button page-item active">
                                    <a href="#" aria-controls="dataTable" data-dt-idx={1} tabIndex={0} className="page-link">
                                        {totalPage}
                                    </a>
                                </li>
                            } */}
                      <li className="paginate_button page-item next" id="dataTable_next">
                        <button className="pagination-action-btn" disabled={data?.skipRec === totalPage - 1 ? true : false} onClick={() => { handlePageNoChange(data?.skipRec + 1); }}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </NavbarSidebarWrapper>
  );
};

export default EventBets;
