import React, { useState, useEffect } from 'react';
import actions from '../../redux/actions';
import * as GrIcons from 'react-icons/gr';
import MarketSettingModel from '../Models/MarketSettingModal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isSuperAdmin, isRoleExits } from '../../utils';
import * as RiIcons from "react-icons/ri";
import ConfimPassDeleteBetModal from '../Models/ConfimPassDeleteBetModal';
import { notifyError, notifySuccess } from '../ToastContext';

const EventTable = ({ eventList }) => {
    const marketSettingModalData = useSelector((state) => state.match?.MarketSetting?.Result)
    const [data, setData] = useState([]);
    const [deleteData, setDeleteData] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        setData(eventList);
    }, [eventList]);

    const getEventList = (eventid) => {
        actions.getEventList(eventid)
    }

    const handleLockMarketToggle = async (marketID, eventId) => {
        const updatedData = data.map(obj => {
            if (obj.MarketId === marketID) {
                return { ...obj, MarketLiquidity: !obj.MarketLiquidity };
            }
            return obj;
        });

        setData(updatedData);
        await actions.betLockMarketAction(marketID);

        getEventList(eventId)
    };

    const handleMatchControl = async (marketID, eventID, status, marketTIme) => {
        const updatedData = data.map(obj => {
            if (obj.MarketId === marketID) {
                return { ...obj, InPlay: status };
            }
            return obj;
        });

        setData(updatedData);
        await actions.matchControlAction(marketID, status, 0, marketTIme);
        getEventList(eventID)
    };

    const handleMarketStatus = async (marketID, status, eventID) => {
        const updatedData = data.map(obj => {
            if (obj.MarketId === marketID) {
                return { ...obj, HideStatus: !status };
            }
            return obj;
        });

        setData(updatedData);
        await actions.openCloseMarketAction(eventID, status)
        getEventList(eventID)
    };

    const handleDelete = async (password) => {
        try {
            const res = await actions.postDeleteMarketType({ ...deleteData, UserKey:password })
            if (res.data.Status) {
                notifySuccess(res.data.Result);
            } else {
                notifyError(res.data.Result);
            }
        } catch (error) {
            notifyError(error);
        } finally{
            setDeleteData(null)
        }
    };


    return (
        <div>
            <MarketSettingModel marketSettingModalData={marketSettingModalData} />
            <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                <thead>
                    <tr role="row">
                        <th>Sr No. x</th>
                        <th>Id</th>
                        <th>Event Name</th>
                        <th>Match Type</th>
                        <th>Market Id</th>
                        {isSuperAdmin() && <th>Market Control</th>}
                        {isSuperAdmin() && <th>Market</th>}
                        {(isRoleExits('match_result') || isRoleExits('fancy_result')) && <th>Result/Fancy</th>}
                        {isSuperAdmin() && <th>Bet Lock</th>}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.map((event, index) => (
                        <tr key={index} className={index % 2 === 0 ? "even hover-row" : "odd hover-row"} >
                            <td>{index + 1}</td>
                            <td>{event?.Id}</td>
                            <td>{event?.MatchName}</td>
                            <td>{event?.MarketType}</td>
                            <td>{event?.MarketId}</td>
                            {isSuperAdmin() && <td>
                                <select className='form-control custom-select-box' value={event?.InPlay} onChange={(e) => handleMatchControl(event?.MarketId, event?.Eventid, e.target.value, event?.MarketTime)}>
                                    <option value="1">Inplay</option>
                                    <option value="0">Open</option>
                                    <option value="2">Suspended</option>
                                </select>
                            </td>}
                            {isSuperAdmin() && <td>
                                <label className="toggle">
                                    <input
                                        type="checkbox"
                                        checked={event?.HideStatus}
                                        onChange={() => handleMarketStatus(event?.MarketId, event?.HideStatus, event?.Eventid)}
                                    />
                                    <div className="slider"></div>
                                </label>
                            </td>}
                            {(isRoleExits('match_result') || isRoleExits('fancy_result')) && <td>
                                <button className="custom-yellow-btn" onClick={() => navigate(`/marketresult/${event?.MarketId}`)}>Match CT</button>

                            </td>}
                            {isSuperAdmin() && <td>
                                <label className="toggle">

                                    <input
                                        type="checkbox"
                                        checked={event?.MarketLiquidity}
                                        onChange={() => handleLockMarketToggle(event?.MarketId, event?.Eventid)}
                                    />
                                    <div className="slider"></div>
                                </label>
                            </td>}
                            <td>
                                <ul className='d-flex list-unstyled'>
                                    {isSuperAdmin() && <li data-toggle="modal" data-target="#marketSettingModal" onClick={() => actions.getMarketSettingModelAction(event?.Eventid, event?.MarketId)}><GrIcons.GrView className='mr-2' /></li>}
                                    {isSuperAdmin() && <li data-toggle="modal" data-target="#ConfirmDeleteBetModal" onClick={() => setDeleteData({ betType: event?.MarketType, Id: event?.MarketId })}><RiIcons.RiDeleteBinLine className='mr-2' /></li>}
                                    {/* <li><FaIcons.FaCalendar className='mr-1' /></li> */}
                                    <li>
                                        <button className="custom-grey-btn" onClick={() => navigate(`/eventbets/${event?.Eventid}/${event?.MarketId}`)}>Bet List</button>
                                        <button className="custom-grey-btn" onClick={() => navigate(`/my-book-event/${event?.MarketId}/${event?.Eventid}/${event?.RunnerName[0]?.SportsId}`, { state: { seriesName: event?.SeriesName, matchName: event?.MatchName } })}>MB</button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ConfimPassDeleteBetModal onConfirm={handleDelete} />
        </div>
    );
}

export default EventTable;


