import NavbarSidebarWrapper from '../../components/navbarSidebarWrapper';
import TableWrapper from '../../components/ViewMatch/TableWrapper';
import { useEffect, useState } from 'react';
import actions from '../../redux/actions';
import { useSelector } from 'react-redux';
import * as BsIcons from 'react-icons/bs';
import ScreenLoader from '../../components/ScreenLoader';
import EventTable from '../../components/ViewMatch/EventTable';

const ViewMatch = () => {

    let matchList = useSelector((state) => state.match?.matchList?.Result);
    let totalrow = useSelector((state) => state.match?.matchList?.Count);
    let eventList = useSelector((state) => state.match?.eventList?.Result);
    let matchType = useSelector((state) => state.match?.matchList?.type);
    //console.log(matchType, 'oppp', useSelector((state) => state.match?.matchList))
    const [action, setAction] = useState(true);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({
        sportsId: '4',
        value: null,
        skipRec: 0,
        take: 10,
        type: 0
    })

    const handleData = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value })
    }

    const handleOpenMarketList = async () => {
        setLoader(true)
        let res = await actions.getOpenMatchList({ ...data, type: 0 });
        if (res) {
            setLoader(false)
        }
    }
    const handleSuspendedMarketList = async () => {
        setLoader(true)
        let res = await actions.getOpenMatchList({ ...data, type: 1 });
        //  let res = await actions.getSuspendedMatchList(data);
        if (res) {
            setLoader(false)
        }
    }
    const handleCompletedMarketList = async () => {
        setLoader(true)
        let res = await actions.getOpenMatchList({ ...data, type: 2 });
        // let res = await actions.getCompletedMatchList(data);
        if (res) {
            setLoader(false)
        }
    }
    useEffect(() => {
        handleOpenMarketList()
    }, [])

    const handleAction = async (eventId) => {
        setLoader(true)
        setAction((prevState) => !prevState)
        let res = await actions.getEventList(eventId)
        if (res) { setLoader(false) }
    }

    const handleSportId = async (e) => {
        let { value } = e.target;
        setData({ ...data, sportsId: value });
        setLoader(true);
        await actions.getOpenMatchList({ ...data, sportsId: value, type: matchType })
        //if (matchType === "open") { await actions.getOpenMatchList({ ...data, sportsId: value, type: 0 }) }
        //if (matchType === "completed") { await actions.getOpenMatchList({ ...data, sportsId: value, type: 2 }) }
        //if (matchType === "suspended") { await actions.getOpenMatchList({ ...data, sportsId: value, type: 1 }) }
        setLoader(false)
    }
    const handleMatchname = async (e) => {
        let { value } = e.target;
        setData({ ...data, value: value });
        if (value.length > 3 || value === "") {
            setLoader(true);
            await actions.getOpenMatchList({ ...data, value: value.trim() === "" ? null : value, type: matchType })
            //if (matchType === "open") { await actions.getOpenMatchList({ ...data, value: value, type: 0 }) }
            //if (matchType === "completed") { await actions.getOpenMatchList({ ...data, value: value, type: 2 }) }
            //if (matchType === "suspended") { await actions.getOpenMatchList({ ...data, value: value, type: 1 }) }
            setLoader(false)
        }
    }


    return (
        <NavbarSidebarWrapper>
            <div>
                {action ?
                    <>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#tab2" onClick={() => handleOpenMarketList()}>
                                    Open
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#tab3" onClick={() => handleSuspendedMarketList()}>
                                    Suspended
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#tab4" onClick={() => handleCompletedMarketList()}>
                                    Completed
                                </a>
                            </li>
                        </ul>
                        {loader ? <ScreenLoader /> :
                            <div className="tab-content">
                                <form>
                                    <div className="row">
                                        <div className="col-6 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="textbox2">Choose Sports</label>
                                                <select className='form-control' value={data.sportsId} name="betStaus" onChange={handleSportId}>
                                                    <option value="4">Cricket</option>
                                                    <option value="1">Soccer</option>
                                                    <option value="2">Tennis</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="textbox2">Search</label>
                                                <input type="text" className='form-control' value={data.value} name="txtvalue" onChange={handleMatchname} />

                                            </div>
                                        </div>
                                    </div>

                                </form>
                                <div className="tab-pane fade show active" id="tab2">
                                    <TableWrapper totalrow={totalrow} data={matchList} showAction={action} handleAction={handleAction} handleData={handleData} />
                                </div>
                                <div className="tab-pane fade" id="tab3">
                                    <TableWrapper totalrow={totalrow} data={matchList} showAction={action} handleAction={handleAction} handleData={handleData} />
                                </div>
                                <div className="tab-pane fade" id="tab4">
                                    <TableWrapper totalrow={totalrow} data={matchList} showAction={action} handleAction={handleAction} handleData={handleData} />
                                </div>
                            </div>}
                    </>
                    :
                    <>
                        {loader ? <ScreenLoader /> :
                            <>
                                <button className='back-btn mb-1' onClick={() => setAction((prevState) => prevState = true)}> <BsIcons.BsArrowReturnLeft /> Back</button>
                                <div className="card shadow mb-4">
                                    <div className="">
                                        <div className="table-responsive">
                                            <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                                                <div className="">
                                                    <div className="">
                                                        {Array.isArray(eventList) && eventList.length > 0 ? (
                                                            <EventTable eventList={eventList} />
                                                        ) : (
                                                            <p>No Data available</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </NavbarSidebarWrapper>
    )
};

export default ViewMatch;