import React, { useState } from 'react'

const ConfimPassDeleteBetModal = ({ modalId, onCancel, onConfirm }) => {

    const [password, setPassword] = useState(''); // State to hold the password input value

    const handleConfirm = () => {
        onConfirm(password); // Pass password to the onConfirm callback
        setPassword(''); // Clear the password input after confirmation
    };

    const handleChange = (e) => {
        setPassword(e.target.value); // Update the password state as user types
    };

    return (
        <div>
            <div
                className="modal fade"
                id="ConfirmDeleteBetModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Confim Password For Delete
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="pws"
                                    placeholder="Password"
                                    value={password}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="modal-cancel-btn" type="button" data-dismiss="modal" onClick={onCancel}>
                                Cancel
                            </button>
                            <button className="modal-submit-btn" type="button" data-dismiss="modal" onClick={handleConfirm}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfimPassDeleteBetModal
