import React, { useState, useEffect } from "react";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";
import moment from "moment";
import ScreenLoader from "../../../components/ScreenLoader";
import { useNavigate } from "react-router-dom";
import { setUserBookName } from "../../../redux/slices/bookEventSlice";

const MarketPLTable = () => {
  let profitLossSportsData = useSelector(
    (state) => state.report.profitLossSportsData?.Result
  );
  let GrandTotalData = useSelector(
    (state) => state.report.profitLossSportsData?.GTotalPl
  );
  let totalrow = useSelector(
    (state) => state.report.profitLossSportsData?.Count
  );
  let userlist = useSelector((state) => state.auth.userDataList?.Result);

  const [fromDate, setFromDate] = useState(
    moment()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [loader, setLoader] = useState(false);
  const [selectedSport, setSelectedSport] = useState(0);
  const [Total, setTotal] = useState({});
  const [totalPage, setTotalPage] = useState(0);
  const navigate = useNavigate();
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [userInput, setUserInput] = useState("");

  let [marketPLReq, setmarketPLReq] = useState({
    startDate: fromDate,
    endDate: toDate,
    psize: 10,
    skipRec: 0,
    type: "1",
    sportsId: selectedSport,
    uid: null,
  });

  const handleProfitLossSports = async () => {
    setLoader(true);
    let data = {
      startDate: fromDate,
      endDate: toDate,
      psize: 10,
      skipRec: 0,
      type: "1",
      sportsId: 0,
      uid: null,
    };
    await actions.getProfitLossSportsAction(data);
    setLoader(false);
  };
  useEffect(() => {
    handleProfitLossSports();
  }, []);

  useEffect(() => {
    setmarketPLReq((prevState) => ({ ...prevState, sportsId: selectedSport }));
  }, [selectedSport]);

  useEffect(() => {
    setmarketPLReq((prevState) => ({
      ...prevState,
      startDate: fromDate,
      endDate: toDate,
    }));
  }, [fromDate, toDate]);

  useEffect(() => {
    if (profitLossSportsData && Array.isArray(profitLossSportsData)) {
      const totals = {};
      for (const obj of profitLossSportsData) {
        for (const key in obj) {
          if (totals.hasOwnProperty(key)) {
            totals[key] += obj[key];
          } else {
            totals[key] = obj[key];
          }
        }
      }
      setTotal(totals);
    }
  }, [profitLossSportsData]);

  const handleFromDateChange = (event) => {
    setFromDate(moment(event.target.value).format("YYYY-MM-DDTHH:mm"));
  };

  const handleToDateChange = (event) => {
    setToDate(moment(event.target.value).format("YYYY-MM-DDTHH:mm"));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    //console.log("submit", marketPLReq);
    await actions.getProfitLossSportsAction(marketPLReq);
    setLoader(false);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    let data = {
      startDate: moment().format("YYYY-MM-DDTHH:mm"),
      endDate: moment().format("YYYY-MM-DDTHH:mm"),
      psize: 10,
      skipRec: 0,
      type: "1",
      sportsId: 0,
      uid: null,
    };
    await actions.getProfitLossSportsAction(data);
    setmarketPLReq(data);
    // setFromDate(moment().format('YYYY-MM-DDTHH:mm'));
    // setToDate(moment().format('YYYY-MM-DDTHH:mm'));
    setSelectedSport(0);
    setUserInput("");
  };

  useEffect(() => {
    setTotalPage(Math.ceil(totalrow / 10));
  }, [totalrow]);

  const handlePageNoChange = (newPageNo) => {
    setmarketPLReq((prevState) => ({ ...prevState, skipRec: newPageNo }));
    let data = { ...marketPLReq, skipRec: newPageNo };
    actions.getProfitLossSportsAction(data);
  };

  const handleUserSelection = (user) => {
    setmarketPLReq((prevState) => ({ ...prevState, uid: user.uid, type: "2" }));
    setUserInput(user.username);
    setUserSuggestions([]);
  };

  useEffect(() => {
    if (userlist && Array.isArray(userlist)) {
      setUserSuggestions(userlist);
    }
  }, [userlist]);

  const handleUserInputChange = (e) => {
    const { value } = e.target;
    setUserInput(value);
  };

  useEffect(() => {
    if (userInput.trim() !== "") {
      actions.getUserSearchList(userInput);
    }
  }, [userInput]);

  const DynamicPagination = (number) => {
    const maxPagesToShow = 4; // Maximum number of pages to show
    const startPage = Math.max(
      1,
      Math.min(number - maxPagesToShow + 1, marketPLReq?.skipRec + 1)
    ); // Determine the start page

    const divs = [];

    for (
      let i = startPage;
      i < startPage + maxPagesToShow && i <= number;
      i++
    ) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${
              marketPLReq?.skipRec + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setmarketPLReq((prevState) => ({
                ...prevState,
                skipRec: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <>
      {loader ? (
        <ScreenLoader />
      ) : (
        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper dt-bootstrap4"
              >
                {/* Start select date view*/}
                <div className="form-group d-flex flex-wrap">
                  <div className="col-md-2 col-sm-3">
                    <label>Sports</label>
                    <select
                      value={selectedSport}
                      onChange={(e) =>
                        setSelectedSport(parseInt(e.target.value))
                      }
                      className="form-control form-control-user"
                    >
                      <option value="0">All</option>
                      <option value="4">Cricket</option>
                      <option value="2">Tennis</option>
                      <option value="1">Soccer</option>
                      <option value="5">Casino</option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-3">
                    <label>From:</label>
                    <input
                      type="datetime-local"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2 col-sm-3">
                    <label>To:</label>
                    <input
                      type="datetime-local"
                      value={toDate}
                      onChange={handleToDateChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2 col-sm-3">
                    <div className="form-group">
                      <label htmlFor="email">User:</label>
                      <input
                        type="text"
                        value={userInput}
                        onChange={handleUserInputChange}
                        className="form-control"
                      />
                      {userSuggestions?.length > 0 && (
                        <ul className="suggestion-list">
                          {userSuggestions?.map((user, index) => (
                            <li
                              key={index}
                              onClick={() => handleUserSelection(user)}
                            >
                              {user?.username}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-2 col-sm-3">
                  <label >Search By User Name</label>
                  <input
                    type="text"
                    className="form-control"
                  />
                </div> */}
                  <div className="col-md-3 col-sm-3">
                    <label>&nbsp;</label>
                    <div className="form-actions no-margin-bottom">
                      <button
                        onClick={handleSubmit}
                        className="custom-yellow-btn"
                        style={{ fontSize: "12px" }}
                      >
                        <i className="fas fa-search"></i> Filter
                      </button>
                      <button
                        onClick={handleReset}
                        className="custom-grey-btn"
                        style={{ fontSize: "12px" }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
                {/* Start List of Table Account statement Component*/}
                <div className="">
                  <div className="">
                    <table className="table table-bordered dataTable">
                      <thead>
                        <tr className="bg-gray-200">
                          <th className="col-date">EVENT</th>
                          <th className="col-date">P&L</th>
                          <th className="col-debit">COMMISION</th>
                          <th className="col-debit">TOTAL P&L</th>
                          <th className="col-debit">COMM DENA</th>
                          <th className="col-credit">COMM LENA</th>
                          <th className="col-credit">DOWN LINE</th>
                          <th className="col-balance">UP LINE</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {profitLossSportsData &&
                          Array.isArray(profitLossSportsData) &&
                          profitLossSportsData?.map((data, index) => (
                            <tr key={index}>
                              <td
                                style={{ color: "#4e73df", cursor: "pointer" }}
                                className="text-no-wrap"
                                onClick={() =>
                                  navigate(
                                    `/profitLossEvent/${
                                      data?.Eventid
                                    }/${encodeURIComponent(
                                      marketPLReq?.uid === null
                                        ? JSON.parse(
                                            localStorage.getItem(
                                              "loginUserDetails"
                                            )
                                          )?.uid
                                        : marketPLReq?.uid
                                    )}/${marketPLReq?.type}`
                                  )
                                }
                              >
                                <span>
                                  {data?.Sport}
                                  {" > "}
                                  {data?.Match}
                                  {"  "}
                                  {moment(data.DateCreation).format(
                                    "YYYY-MM-DDTHH:mm:ss"
                                  )}
                                </span>{" "}
                              </td>
                              <td data-value={data?.pf}>
                                {data?.pf < 0 ? -1 * data?.pf : data?.pf}
                              </td>
                              <td data-value={data?.comm}>
                                {data?.comm < 0 ? -1 * data?.comm : data?.comm}
                              </td>
                              <td data-value={data?.totalpl}>
                                {data?.totalpl < 0
                                  ? -1 * data?.totalpl
                                  : data?.totalpl}
                              </td>
                              <td data-value={data?.Comm_Dena}>
                                {data?.Comm_Dena < 0
                                  ? -1 * data?.Comm_Dena
                                  : data?.Comm_Dena}
                              </td>
                              <td data-value={data?.Comm_Lena}>
                                {data?.Comm_Lena < 0
                                  ? -1 * data?.Comm_Lena
                                  : data?.Comm_Lena}
                              </td>
                              <td data-value={data?.DPL}>
                                {data?.DPL < 0 ? -1 * data?.DPL : data?.DPL}
                              </td>
                              <td data-value={data?.UPL}>
                                {data?.UPL < 0 ? -1 * data?.UPL : data?.UPL}
                              </td>
                            </tr>
                          ))}
                        <tr className="bg-gray-400">
                          <td>
                            <b className="text-no-wrap">TOTAL</b>
                          </td>
                          <td>
                            <b data-value={Total?.pf}>{Total?.pf}</b>
                          </td>
                          <td>
                            <b data-value={Total?.comm}>
                              {Math.round(Total?.comm)}
                            </b>
                          </td>
                          <td>
                            <b data-value={Total?.totalpl}>
                              {Math.round(Total?.totalpl)}
                            </b>
                          </td>
                          <td>
                            <b data-value={Total?.Comm_Dena}>
                              {Total?.Comm_Dena}
                            </b>
                          </td>
                          <td>
                            <b data-value={Total?.Comm_Lena}>
                              {Total?.Comm_Lena}
                            </b>
                          </td>
                          <td>
                            <b data-value={Total?.DPL}>
                              {Math.round(Total?.DPL)}
                            </b>
                          </td>
                          <td>
                            <b className={Total?.UPL}>
                              {Math.round(Total?.UPL)}
                            </b>
                          </td>
                        </tr>
                        <tr className="bg-gray-400">
                          <td>
                            <b className="text-no-wrap">GRAND TOTAL </b>
                          </td>
                          <td>
                            <b data-value={GrandTotalData?.pf}>
                              {GrandTotalData?.pf}
                            </b>
                          </td>
                          <td>
                            <b data-value={GrandTotalData?.comm}>
                              {GrandTotalData?.comm}
                            </b>
                          </td>
                          <td>
                            <b data-value={GrandTotalData?.totalpl}>
                              {GrandTotalData?.totalpl}
                            </b>
                          </td>
                          <td>
                            <b data-value={GrandTotalData?.Comm_Dena}>
                              {GrandTotalData?.Comm_Dena}
                            </b>
                          </td>
                          <td>
                            <b data-value={GrandTotalData?.Comm_Lena}>
                              {GrandTotalData?.Comm_Lena}
                            </b>
                          </td>
                          <td>
                            <b data-value={GrandTotalData?.DPL}>
                              {GrandTotalData?.DPL}
                            </b>
                          </td>
                          <td>
                            <b data-value={GrandTotalData?.UPL}>
                              {GrandTotalData?.UPL}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {profitLossSportsData &&
                      profitLossSportsData?.length > 0 && (
                        <div className="row m-1">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="dataTable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Total Page : {totalPage}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="dataTable_paginate"
                            >
                              <ul className="pagination d-flex justify-content-end">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="dataTable_previous"
                                >
                                  <button
                                    className="pagination-action-btn"
                                    disabled={
                                      marketPLReq?.skipRec === 0 ? true : false
                                    }
                                    onClick={() => {
                                      handlePageNoChange(
                                        marketPLReq?.skipRec - 1
                                      );
                                    }}
                                  >
                                    Previous
                                  </button>
                                </li>
                                {DynamicPagination(totalPage)}
                                <li
                                  className="paginate_button page-item next"
                                  id="dataTable_next"
                                >
                                  <button
                                    className="pagination-action-btn"
                                    disabled={
                                      marketPLReq?.skipRec === totalPage - 1
                                        ? true
                                        : false
                                    }
                                    onClick={() => {
                                      handlePageNoChange(
                                        marketPLReq?.skipRec + 1
                                      );
                                    }}
                                  >
                                    Next
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MarketPLTable;
