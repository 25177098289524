import { POSTMARKETBETADMIN, GETEXPOSERBETLIST, BETBLOCKMARKET, GETMARKETBETPLLIST, GETTENNISSCORE, GETBETFAREBMFANCYAPI, GETBETFAREMARKETOTHERAPI, GETSCOREAPI, GETBETFARETOSSAPI, GETCUSTOMEMARKET, GETEVENTDETAIL, GETFANCYBETLIST, GETFANCYPOSTIONAPI, GETUSERBETMODELAPI, GETUSERBOOK, POSTCUSTOMEMARKET, POSTFANCYBETADMIN, UPDATEFACNYSTATUS, GETUNDECTAREFANCYAPI, GETACTIVEFANCYAPI, GETCUSTOMEBMAPI, POSTCUSTOMFANCY, GETFANCYCURRENT, GETPROFITLOSSRUNNERAPI, GETPROFITLOSSRUNNEREVNTAPI, GETMARKETBETLIST, GETDELETEUSERBET, GETEVENTLIST, GETBLOCKEVENTLIST, GETBLOCKSPORTLIST, POSTBLOCKSPORT, GETCUSTOMFANCYBYIDAPI, POSTDELETEMARKETTYPE } from './url'
import axios from 'axios';

class BookEventServiceClass {

    constructor() {
        this.userData = JSON.parse(localStorage.getItem("loginUserDetails"));
        this.headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userData?.Token}`
        };
        this.axiosInstance = axios.create({
            headers: this.headers,
            timeout: 5000 // Timeout set to 5 seconds
        });
    }

    async getEventDetailService(eventId) {
        return await axios.get(`${GETEVENTDETAIL}?eventId=${eventId}`, {
            headers: this.headers,
        });
    }

    async GetBetModelList(eventId) {
        return await this.axiosInstance.get(`${GETUSERBETMODELAPI}?eventid=${eventId}`, {
            headers: this.headers,
        });
    }

    async GetFancyPostion(marketId) {
        return await this.axiosInstance.get(`${GETFANCYPOSTIONAPI}?marketId=${marketId}`, {
            headers: this.headers,
        });
    }

    async getBetfareTossOddsService(marketids) {
        return await axios.this.axiosInstance.get(`${GETBETFARETOSSAPI}?marketids=${marketids}T`, {
            headers: this.headers,
        });
    }

    async getBetfareMarketAndOtherService(marketids) {
        return await this.axiosInstance.get(`${GETBETFAREMARKETOTHERAPI}/${marketids}`, {
            headers: this.headers,
        });
    }

    async postBlockMarketService(data) {
        return await axios.post(`${BETBLOCKMARKET}`, data, {
            headers: this.headers,
        });
    }

    async getUserBookService(bookData) {
        return await axios.get(`${GETUSERBOOK}?marketId=${bookData.marketId}&uid=${bookData.uid}`, {
            headers: this.headers,
        });
    }

    async GetCustomeMarketService(marketId) {
        return await axios.get(`${GETCUSTOMEMARKET}?MarketId=${marketId}`, {
            headers: this.headers,
        });
    }

    async postCustomeMarketService(data) {
        return await axios.post(`${POSTCUSTOMEMARKET}`, data, {
            headers: this.headers,
        });
    }

    async getBetfareRetsBMAndFancyService(marketids) {
        return await this.axiosInstance.get(`${GETBETFAREBMFANCYAPI}/${marketids}`, {
            headers: this.headers,
        });
    }

    async getMarketBetListService(data) {
        return await axios.post(`${GETMARKETBETLIST}?marketid=${data.marketId}&fancyid=${data.fancyId}&MarketType=${data.MarketType}`, null, {
            headers: this.headers,
        });
    }

    async getExposerBetListService(data) {
        return await axios.post(`${GETEXPOSERBETLIST}?uid=${data.uid}`, null, {
            headers: this.headers,
        });
    }


    async getMarketBetListPLService(plData) {
        let jsonData = JSON.parse(localStorage.getItem("loginUserDetails"))
        return await axios.get(`${GETMARKETBETPLLIST}?MarketId=${plData.marketId}&uid=${plData?.uid === null || plData?.uid === undefined ? jsonData.uid : plData?.uid}&MarketType=${plData.marketType}&fancyid=${plData.fancyId}`, {
            headers: this.headers,
        });
    }

    async updateFancyStautsService(data) {
        return await axios.get(`${UPDATEFACNYSTATUS}?MarketId=${data.marketId}&fancyid=${data.fancyId}&status=false`, {
            headers: this.headers,
        });
    }

    async postFancyBetAdminService(data) {
        return await axios.post(`${POSTFANCYBETADMIN}`, data, {
            headers: this.headers,
        });
    }
    async postMarketBetAdminService(data) {
        return await axios.post(`${POSTMARKETBETADMIN}`, data, {
            headers: this.headers,
        });
    }

    async getUndeclareFancyListService(MarketId) {
        return await this.axiosInstance.get(`${GETUNDECTAREFANCYAPI}?MarketId=${MarketId}`, {
            headers: this.headers,
        });
    }
    async getActiveFancyListService(MarketId) {
        return await axios.get(`${GETACTIVEFANCYAPI}?MarketId=${MarketId}`, {
            headers: this.headers,
        });
    }
    async getCustomeBMService(MarketId) {
        return await axios.get(`${GETCUSTOMEBMAPI}?MarketId=${MarketId}`, {
            headers: this.headers,
        });
    }

    async postCustomeFancyService(data) {
        return await axios.post(`${POSTCUSTOMFANCY}`, data, {
            headers: this.headers,
        });
    }

    async getFancyCurrentService(data) {
        return await axios.get(`${GETFANCYCURRENT}?selectionId=${data.selectionId}&marketId=${data.marketId}`, {
            headers: this.headers,
        });
    }

    async GetProfitLossOnRunners(marketId, sportId) {
        return await axios.get(`${GETPROFITLOSSRUNNERAPI}?marketId=${marketId}&sportsId=${sportId}`, {
            headers: this.headers,
        });
    }

    async GetProfitLossOnRunnersEvent(eventID, sportId) {
        return await this.axiosInstance.get(`${GETPROFITLOSSRUNNEREVNTAPI}?eventid=${eventID}&sportsId=${sportId}`, {
            headers: this.headers,
        });
    }

    async GetDeleteUserBetService(betData) {
        return await axios.get(`${GETDELETEUSERBET}?id=${betData.id}&betType=${betData.MarketType}&pws=12345`, {
            headers: this.headers,
        });
    }

    async GetScoreDataService(eventId) {
        return await axios.get(`${GETSCOREAPI}/${eventId}`, {
            headers: this.headers,
        });
    }

    async GetBlockEventListService(sportId) {
        return await axios.get(`${GETBLOCKEVENTLIST}?sportId=${sportId}`, {
            headers: this.headers,
        });
    }

    async GetBlockSportListService() {
        return await axios.get(`${GETBLOCKSPORTLIST}`, {
            headers: this.headers,
        });
    }

    async GetTennisScoreService(data) {
        return await this.axiosInstance.get(`${GETTENNISSCORE}/${data?.sportId}/${data?.eventId}`, {
            headers: this.headers,
        });
    }

    async PostBlockSportService(data) {
        return await axios.post(`${POSTBLOCKSPORT}`, data, {
            headers: this.headers,
        });
    }

    async getCustomFancyByIdService(data) {
        return await axios.get(`${GETCUSTOMFANCYBYIDAPI}?MarketId=${data.MarketId}&fancyid=${data.selectionId}`, {
            headers: this.headers,
        });
    }

    async postDeleteMarketService(data) {
        return await axios.post(`${POSTDELETEMARKETTYPE}`, data, {
            headers: this.headers,
        });
    }

}

const BookEventService = new BookEventServiceClass()

export default BookEventService;